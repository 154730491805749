import React, { Component } from "react";
import "./Tnc.scss";
import TncLogos from "../../../assets/images/newlogo.svg";
import { Image } from "react-bootstrap";
class Tnc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sort: "",
      keyword: "",
      search: "",
      totalOrders: [],
    };
  }

  componentDidMount() {
    console.log("tnc page");
  }
  handleRouteChange(val) {
    this.props.history.push(val);
  }

  render() {
    return (
      <section class="terms-section">
        <div class="container">
          <div className="mx-auto mt-4 mb-5 d-flex justify-content-start">
            <Image src={TncLogos} alt="Tnc Logo" className="img-fluid pointer" onClick={ ()=> this.handleRouteChange('/dashboard')} />
          </div>
          <p class="text-center mt-4 mb-0">Last modified: January 8, 2024</p>
          <h1 class="heading overflow-hidden text-center mt-0 mx-auto mb-0">
            DelivaHere LLC
          </h1>
          <h2 class="headingh2 text-center mx-auto mb-4 d-none">
            July 2nd 2020
            <span class="d-block">v1.0</span>
          </h2>
          <h3 class="heading overflow-hidden text-center mx-auto mb-0">
            <span class="d-block">Service Terms & Conditions</span></h3>
          <div class="row">
            <div class="col-md-12">
              <h2 class="heading3 text-black mb-2 mt-3">Introduction:</h2>
              <p>
                Please, read the following information carefully. It contains
                the Terms of Use governing your access to and use of the
                DelivaHere mobile application, DelivaHere mobile application,{" "}
                <a href="http://www.delivahere.com/" target="_blank">
                  www.delivahere.com
                </a>
                , and to any other associated services, or associated websites
                owned and/or operated by <strong>DelivaHere LLC</strong>{" "}
                (collectively hereinafter the “Service”); and to any
                correspondence by e-mail between Us and You. The mobile
                application may at times be individually referred to as
                “Application” and the website may at times be individually
                referred to as “Website.” Please, read these terms carefully
                before using the Service. Using the Service indicates that You
                accept these terms. If You do not accept these terms, do not use
                the Service. These Terms of Use are legally enforceable and
                binding agreement between You and
                <strong> DelivaHere LLC</strong> and shall be referred to as
                “Agreement”’ or “Terms of Use.”
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                1. Parties To This Agreement And Consideration.
              </h2>
              <p>
                The parties to this Agreement (the "Agreement") are You,
                (hereinafter “You” or the "User"), and DelivaHere LLC,
                (hereinafter the "Company," “We,” “Our,” or “Us”).{" "}
              </p>
              <ul class="list-number">
                <li>
                  1.1. By accessing the Service and for other good and valuable
                  consideration, the sufficiency of which is acknowledged by You
                  and the Company, You hereby agree to be bound by all the terms
                  and conditions set forth in this Agreement.
                </li>
                <li>
                  1.2. Subject to your acceptance of the terms and conditions
                  set forth in this Agreement, The Company agrees to grant You a
                  limited personal non-transferable right to access the contents
                  of Service operated by the Company.{" "}
                </li>
                <li>
                  1.3. This Agreement is subject to change by the Company at any
                  time and changes shall become effective upon notice to USERS
                  by posting such changes directly on the Website or
                  Application; or by sending an email from DelivaHere LLC to the
                  email address associated with your User account.{" "}
                </li>
                <li>
                  1.4. You may not alter, delete, add or change or edit any of
                  these terms and conditions, and any such attempted alteration
                  shall be void and of no effect.{" "}
                </li>
                <li>
                  1.5. Any action on your part to Bookmark to a page on the
                  Service whereby the Warning Page, the Age Verification Page,
                  and/or the Terms of Use Page is bypassed shall constitute an
                  implicit acceptance by You of all the Terms and Conditions set
                  forth herein as well as an explicit acknowledgment by You of
                  the fact that You are at least 13 years old.{" "}
                </li>
              </ul>
              <h2 class="heading3 text-black mb-2 mt-2">
                2. Use of Service:{" "}
                <span>You agree to not do any of the following:</span>
              </h2>
              <ul class="circle-list">
                <li>violate any laws or Company policies; </li>
                <li>be false or misleading;</li>
                <li>infringe any third-party right; </li>
                <li>
                  distribute or contain spam, chain letters, or pyramid schemes;
                </li>
                <li>
                  distribute viruses or any other technologies that may harm the
                  Company or the interests or property of the Company’s users;
                </li>
                <li>
                  use, copy, modify, distribute, or otherwise transfer any of
                  the Company’s content on the Service or any other person's
                  content posted on the Service, unless expressly authorized to
                  do so;{" "}
                </li>
                <li>
                  use any robot, spider, scraper or other automated means to
                  access the Service and collect content for any purpose without
                  our express written permission;
                </li>
                <li>
                  harvest or otherwise collect information about others,
                  including email addresses, without their instruction;{" "}
                </li>
                <li>
                  bypass measures used to prevent or restrict access to the
                  Service;{" "}
                </li>
                <li>
                  copy, decompile, reverse engineer, disassemble, attempt to
                  derive source code of, modify, create derivative works of the
                  Service, any updates, or any part thereof;{" "}
                </li>
                <li>
                  copy or distribute, publish or exploit the Service, or any
                  content in the Service, unless you have received express
                  written permission from Us;{" "}
                </li>
                <li>
                  modify or otherwise make any derivative use of the Service;
                </li>
                <li>
                  use any data mining, robots or similar data gathering or
                  extraction methods on the Service;{" "}
                </li>
                <li>
                  downloading (other than page caching) of any portion of the
                  Service or any information contained therein;{" "}
                </li>
                <li>
                  reverse engineering or accessing the Service in order to build
                  a competitive product or service;{" "}
                </li>
                <li>
                  using the Service other than for its intended purposes;{" "}
                </li>
                <li>
                  create an account or post any content{" "}
                  <strong>if you are under the age of 13.</strong>
                </li>
              </ul>
              <h2 class="heading3 text-black mb-2 mt-3">
                3. User's Code of Conduct:
              </h2>
              <p>
                You agree, warrant and covenant to use the Service in accordance
                with the following Code of Conduct and You agree and acknowledge
                that in our sole discretion we may, but are under no obligation
                to reject or delete any transaction posted or uploaded by You
                which violates any of the following provisions, and/or to
                terminate Your right to use or access the Service for violating
                these provisions:
              </p>
              <ul class="circle-list">
                <li>
                  that it is unlawful, harmful, threatening, abusive, harassing,
                  defamatory, libelous, invasive of another's privacy, or is
                  harmful to minors in any way;
                </li>
                <li>
                  that is pornographic or depicts a human being engaged in
                  actual sexual conduct in any way;{" "}
                </li>
                <li>
                  that harasses, degrades, intimidates or is hateful toward an
                  individual or group of individuals on the basis of religion,
                  gender, sexual orientation, race, ethnicity, age, or
                  disability;{" "}
                </li>
                <li>
                  that impersonates any person or entity, including, but not
                  limited to, a Company’s employee, or falsely states or
                  otherwise misrepresents your affiliation with a person or
                  entity (this provision does not apply to content that
                  constitutes lawful non-deceptive parody of public figures.);
                </li>
                <li>
                  that includes personal or identifying information about
                  another person without that person's explicit instruction;{" "}
                </li>
                <li>
                  that is false, deceptive, misleading, deceitful,
                  misinformative, or constitutes "bait and switch";{" "}
                </li>
                <li>
                  that infringes any patent, trademark, trade secret, copyright
                  or other proprietary rights of any party, or Content that You
                  do not have a right to make available under any law or under
                  contractual or fiduciary relationships;{" "}
                </li>
                <li>
                  {" "}
                  that constitutes or contains "affiliate marketing," "link
                  referral code," "junk mail," "spam," "chain letters," "pyramid
                  schemes," or unsolicited commercial advertisement;{" "}
                </li>
                <li>
                  that constitutes or contains any form of advertising or
                  solicitation if: posted in areas of the Service which are not
                  designated for such purposes; or emailed to the Service users
                  who have not indicated in writing that it is okay to contact
                  them about other services, products or commercial interests;{" "}
                </li>
                <li>
                  that includes links to commercial services or web sites,
                  except as allowed in "services";{" "}
                </li>
                <li>
                  that advertises any illegal service or the sale of any items
                  the sale of which is prohibited or restricted by any
                  applicable law, including without limitation items the
                  processing, transportation, or sale of which is prohibited or
                  regulated by federal, state and local law;{" "}
                </li>
                <li>
                  that contains software viruses or any other computer code,
                  files or programs designed to interrupt, destroy or limit the
                  functionality of any computer software or hardware or
                  telecommunications equipment;{" "}
                </li>
                <li>
                  that disrupts the normal flow of dialogue with an excessive
                  amount of content (flooding attack) to the Service, or that
                  otherwise negatively affects, interferes, or disrupts other
                  users' ability to use the Service; or that damages, disables,
                  overburdens or impairs the functions of the Service.{" "}
                </li>
                <li>
                  that employs misleading email addresses, or forged headers or
                  otherwise manipulated identifiers in order to disguise the
                  origin of content transmitted through the Service;{" "}
                </li>
                <li>
                  contact anyone who has asked not to be contacted, or make
                  unsolicited contact with anyone for any commercial purpose;{" "}
                </li>
                <li>"stalk" or otherwise harass anyone; </li>
                <li>
                  collect personal data about other users for commercial or
                  unlawful purposes;
                </li>
                <li>
                  use automated means, including spiders, robots, crawlers, data
                  mining tools, or the like to download data from the Service,
                  unless expressly permitted by Us;{" "}
                </li>
                <li>
                  post non-local unless permitted or otherwise irrelevant
                  content, repeatedly post the same or similar content or
                  otherwise impose an unreasonable or disproportionately large
                  load on our infrastructure;{" "}
                </li>
                <li>
                  post the same item, transaction, or service in more than one
                  classified category or forum;
                </li>
                <li>
                  attempt to gain unauthorized access to the Company’s computer
                  systems or engage in any activity that disrupts, diminishes
                  the quality of, interferes with the performance of, or impairs
                  the functionality of, the Service;
                </li>
                <li>
                  use any form of automated device or computer program that
                  enables the submission of postings on the Service without each
                  posting being manually entered by the author thereof (an
                  "automated posting device"), including without limitation, the
                  use of any such automated posting device to submit postings in
                  bulk, or for automatic submission of postings at regular
                  intervals;{" "}
                </li>
              </ul>
              <h2 class="heading3 text-black mb-2 mt-2">
                4. Information Collected
              </h2>
              <p>
                The information which DelivaHere LLC collects through the
                Service may include Personal and Financial Information
                (hereinafter the "Personal Information") which refers to
                information that helps it identify users or viewers of the
                Service (collectively, "Users" and each a "User" or “You” and
                shall refer to Registered User as defined in Service Privacy
                Policy). The Personal Information includes data such as a User’s
                name, street address, phone number, facsimile number, email
                address, username and password, bank account details and credit
                card numbers and information. Users are responsible for keeping
                all Personal Information current and may change any previously
                entered information through the Service in the manner set forth
                below. DelivaHere LLC may utilize Personal Information, without
                limitation, for the following purposes: (i) establishing and
                verifying user identities; (ii) opening, maintaining,
                administering and servicing Registered User (as defined below)
                accounts; (iii) processing, servicing or enforcing transactions
                and sending communications relating to the Service; (iv)
                providing User support in connection with User-use of the
                Service; (v) providing service updates for the Service; (vi)
                providing promotional notices and offers and other DelivaHere
                LLC information; (vii) responding to User-posed inquiries and
                comments; (viii) maintaining the security of the Service and
                Company-systems; (ix) evaluating Use of the Service for
                potential improvements and otherwise collecting payment for
                services or compensating users for transaction. The Service may
                also collect data that cannot be traced back to a specific
                individual (hereinafter the "Anonymous Information"). For
                example, DelivaHere LLC may be able to keep count of how many
                Users have viewed the Service and specific content therein, or
                key words utilized to find the Service, but this information
                does not necessarily include information regarding Users’ names,
                street addresses, phone numbers, or email addresses. Users who
                view the Service but do not enter their Personal Information are
                generally anonymous Users. Only Anonymous Information and no
                Personal Information will be intentionally collected by
                DelivaHere LLC from anonymous Users. The Service may use browser
                cookies to collect Anonymous Information. The Service may also
                incorporate web beacons or similar tracking technologies to
                allow DelivaHere LLC to track how the Service is used. Such
                technologies are used to collect Anonymous Information, e.g. the
                name of the User’s internet service provider, the IP address of
                the User’s computer, User’s browser software and operating
                system, the identity of any linked-form or linked-to website and
                other similar information. Furthermore, DelivaHere LLC may,
                either directly or through a third party, track Users’ conduct
                on the Service, including, without limitation, areas of the
                Service visited, topic(s) viewed, most popular pages of the
                Service, Users’ Internet connection type and browser use
                DelivaHere LLC may combine a User’s Anonymous Information with
                similar information collected from other Users to help improve
                the Service.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                5. Service Use of Information:
              </h2>
              <p>
                Notwithstanding anything herein to the contrary, by submitting
                Personal Information, You grant DelivaHere LLC, its officers,
                subsidiaries, affiliates, successors, assigns, managers,
                members, agents, service providers, suppliers, and employees the
                right to store and distribute your Personal Information. We
                gather and use information as follows: We will receive and store
                any information you enter on the Service or give us in any other
                way that personally identifies you to improve your experience at
                the Service, to get a better general understanding of the type
                of individuals visiting the Service and to enable us to contact
                you when needed. By submitting this personally identifiable
                information to Our Service you agree to receive email and postal
                mail from the Company and other third party marketers. You
                further understand and agree that by using the Service, you are
                extending an express invitation for the Company, and other third
                marketers to contact you by telephone at the numbers you have
                provided and you hereby consent to any such calls even if your
                phone numbers is on any federal state or other do not call
                lists. We believe in permission based marketing and Users may be
                provided with the option to unsubscribe through an unsubscribe
                link at the bottom of these emails. Users may also have the
                option of completely removing their information from our
                database by submitting the request at
                <a href="http://unsubscribe.delivahere.com/" target="_blank">
                  {" "}
                  http://unsubscribe.delivahere.com
                </a>
                . Please, note that user information may be cached in search
                engine indexes after removal and that the Service and DelivaHere
                LLC have no control over such caching.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                6. Revisions to Terms & Conditions
              </h2>
              <p>
                DelivaHere LLC reserves the right to revise, change, amend,
                alter or modify (hereinafter, "Update") these Terms of Use and
                its other policies and agreements at any time and in any manner.
                The User is responsible for periodically checking for any
                Updates of these Terms of Use. Any Updates supersede any prior
                versions immediately upon posting on the Service. Using the
                Service after any such Update or changes to the Terms of Use
                shall constitute consent to changed terms or practices.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                7. Accounts, Passwords and Registration to the Service
              </h2>
              <p>
                You agree that We are not responsible or liable for the personal
                protection and security of any password or username that you may
                use to access the Service. You are responsible for maintaining
                the confidentiality of the password and account, and can help
                keep your account secure by using a strong password. You are
                solely responsible for any and all direct or indirect damages
                and liable for all activity conducted on the Service that can be
                linked or traced back to your username or password. If You lose
                a password to your account or are subject to a stolen password,
                You must immediately notify Us.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                8. Content from the Service.
              </h2>
              <p>
                As between You and DelivaHere LLC, You own any and all content
                that You submit to the Service. You grant DelivaHere LLC and its
                affiliates a right to distribute and send your content to
                third-parties to perform the transaction(s) being requested.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                9. Abusing the Service.
              </h2>
              <p>
                Please send Us an email at terms@delivahere.com to tell Us about
                any problems or offensive content so that together we can keep
                the Service working properly. We may limit or terminate our
                service, remove hosted content and/or take technical and legal
                steps to keep Users off the Service if we think that they are
                creating problems or acting inconsistently with the letter or
                spirit of our policies. However, whether We decide to take any
                of these steps, remove hosted content or keep a user off Service
                or not, We do not accept any liability for monitoring the
                Service or for unauthorized or unlawful content on the Service
                or use of the Service by Users.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                10. Limitations on Services.
              </h2>
              <p>
                You acknowledge that the Company may establish limits concerning
                use of the Service, including the maximum number of days that
                content will be retained by the Service, the maximum number and
                size of postings, email messages, or other content that may be
                transmitted or stored by the Service, and the frequency with
                which You may access the Service. You agree that the Company has
                no responsibility or liability for the deletion or failure to
                store any content maintained or transmitted by the Service. You
                acknowledge that the Company reserves the right at any time to
                modify or discontinue the Service (or any part thereof) with or
                without notice, and that the Company shall not be liable to You
                or to any third party for any modification, suspension or
                discontinuance of the Service.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                11. Posting Agents.{" "}
              </h2>
              <p>
                A "Posting Agent" is a third-party agent, service, or
                intermediary that offers to post content on the Service on
                behalf of others. To moderate demands on the Service's
                resources, You may not use a Posting Agent to post any content
                on the Service without express permission or license from Us.
                Correspondingly, Posting Agents are not permitted to post
                content on behalf of others, to cause content to be so posted,
                or otherwise access the Service to facilitate posting content on
                behalf of others, except with express permission or license from
                the Company.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">12. No Spam Policy.</h2>
              <p>
                You understand and agree that sending unsolicited email
                advertisements to Our email addresses or through Our computer
                systems or Our Service, is expressly prohibited by this
                Agreement. Any unauthorized use of Our computer systems is a
                violation of this Agreement and certain federal and state laws,
                including without limitation the Computer Fraud and Abuse Act
                (18 U.S.C. § 1030 et seq.). Such violations may subject the
                sender and his or her agents to civil and criminal penalties.
                This section shall survive the termination of this Agreement.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                13. Intellectual Property Rights & Service Content.{" "}
              </h2>
              <p>
                DelivaHere LLC, reserves all of its intellectual property
                rights. This Agreement does not grant you any right or license
                with respect to any of our copyrights, trademarks, service
                marks, graphics and logos. The Service contains content from Us,
                You, and other users. You agree not to copy, modify, distribute,
                or make any derivative use of any content whatsoever from the
                Service, including all our copyrights or trademarks. When You
                give Us content, You are granting Us a non-exclusive, worldwide,
                perpetual, irrevocable, royalty-free, sub-licensable right to
                exercise the copyright, publicity, and database rights to that
                content. By posting to the Service You are acknowledging that
                all content posted belongs to You or You have permission to post
                it and further You will not post anyone’s image or likeness
                without their express permission. If You believe that your
                rights have been violated, please notify Us at{" "}
                <a href="mailto:terms@delivahere.com" target="_blank">
                  {" "}
                  terms@delivahere.com{" "}
                </a>
                and we will investigate the matter. We reserve the right to
                remove content where we have grounds for suspecting the
                violation of these Terms of Use or of any party's rights. Any
                content posted by a User other than by Us and/or DelivaHere LLC
                shall not be deemed and does not reflect the thoughts, comments,
                ideas, opinions, or otherwise of DelivaHere LLC and/or Us.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                14. Reporting Intellectual Property Infringements.
              </h2>
              <p>
                Do not post content that infringes the rights of third parties.
                This includes, but is not limited to, content that infringes on
                intellectual property rights such as copyright and trademark
                such as offering counterfeit items for sale. We reserve the
                right to remove content where we have grounds for suspecting the
                violation of these Terms of Use, our policies or of any party's
                rights. If You have a good faith belief that a listing on the
                Service infringes your copyright, trademark, or other
                intellectual property rights, then You must immediately contact
                Us in writing with all supporting documents and details
                regarding such infringement. No request or complaint regarding
                any infringement of intellectual property shall be considered
                unless proper documentation evidencing ownership of intellectual
                property are provided. You must a) clearly identify the material
                on the Service that You claim is infringing so that we may
                locate it on the Service; b) provide a statement by You that You
                have a good faith belief that the disputed use is not authorized
                by the intellectual property owner, its agent, or the law; c)
                provide a statement declaring under penalty of perjury that (1)
                the above information in your notice is accurate, and (2) that
                You are the owner of the intellectual property interest involved
                or that You are authorized to act on behalf of that owner; d)
                provide your address, telephone number, and email address; and
                e) your physical or electronic signature. We will remove the
                infringing posting(s), subject to the procedures outlined in the
                Digital Millennium Copyright Act. This section shall survive the
                termination of this Agreement.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">15. Liability.</h2>
              <p>
                You agree not to hold Us or our Affiliates responsible for
                things other users post or do. We do not guarantee the accuracy
                of postings or user communications or the quality, safety, or
                legality of what's offered. We also cannot guarantee continuous
                or secure access to our services. Accordingly, to the extent
                legally permitted we expressly disclaim all warranties,
                representations and conditions, express or implied, including
                those of quality, merchantability, merchantable quality,
                durability, fitness for a particular purpose and those arising
                by statute. We are not liable for any loss, whether of money
                (including profit), goodwill, or reputation, or any special,
                indirect, or consequential damages arising out of your use of
                the Service, even if You advise Us or we could reasonably
                foresee the possibility of any such damage occurring. Some
                jurisdictions do not allow the disclaimer of warranties or
                exclusion of damages, so such disclaimers and exclusions may not
                apply to You. This section shall survive the termination of this
                Agreement.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                16. Disclaimer of Warranties.
              </h2>
              <p>
                You agree that use of the Service is entirely at your own risk.
                The Service is provided on an "as is" or "as available" basis,
                without any warranties of any kind. All express and implied
                warranties, including, without limitation, the warranties of
                merchantability, fitness for a particular purpose, and
                non-infringement of proprietary rights are expressly disclaimed
                to the fullest extent permitted by law. To the fullest extent
                permitted by law, we disclaim any warranties for the security,
                reliability, timeliness, accuracy, and performance of the
                Service. To the fullest extent permitted by law, we disclaim any
                warranties for other services or goods received through or
                advertised on the Service, or accessed through any links on the
                Service. To the fullest extent permitted by law, we disclaim any
                warranties for viruses or other harmful components in connection
                with the Service.{" "}
              </p>
              <ul class="circle-list">
                <li>
                  Some jurisdictions do not allow the disclaimer of implied
                  warranties. In such jurisdictions, some of the foregoing
                  disclaimers may not apply to You insofar as they relate to
                  implied warranties. This section shall survive the termination
                  of this Agreement.{" "}
                </li>
              </ul>
              <h2 class="heading3 text-black mb-2 mt-2">17. Indemnity. </h2>
              <p>
                You agree to indemnify and hold the Company, its officers,
                subsidiaries, affiliates, successors, assigns, managers,
                members, agents, service providers, suppliers and employees,
                harmless from any claim or demand, including reasonable attorney
                fees and court costs, made by any third party due to or arising
                out of content You submit, post or make available through the
                Service, your use of the Service, your violation of this
                Agreement, your breach of any of the representations and
                warranties herein, or your violation of any rights of another.
                This section shall survive the termination of this Agreement.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                18. Terminating our Services.{" "}
              </h2>
              <p>
                You may terminate or stop using the Service at any time.
                DelivaHere LLC may add or remove functionalities or features of
                the Service and/or also stop providing the Service to You
                completely. You agree that We, at our sole discretion, have the
                right (but not the obligation) to delete or deactivate your
                account, block your email or IP address, or otherwise terminate
                your access to or use of the Service (or any part thereof),
                immediately and without notice, and remove and discard any
                content within the Service, for any reason, including, without
                limitation, if We believe that You have acted inconsistently
                with the letter or spirit of this Agreement. Further, You agree
                that We shall not be liable to You or any third-party for any
                termination of your access to the Service. Further, You agree
                not to attempt to use the Service after said termination.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                19. Dispute Resolution.
              </h2>
              <p>
                This Agreement or any claim, cause of action or dispute
                (“claim”) arising out of or related to this Agreement is
                governed by the laws of the State of Delaware. Any disputes
                relating in any way to your use of the DelivaHere LLC Service
                shall be submitted to confidential & binding arbitration in,
                except to the extent that you have in any manner violated or
                threatened to violate DelivaHere LLC’s intellectual property
                rights. DelivaHere LLC may seek injunctive or other appropriate
                relief in any state or federal court in the state of Delaware
                and you consent to exclusive jurisdiction and venue in such
                courts. Arbitration under this agreement shall be conducted
                under the commercial rules then prevailing of the American
                Arbitration Association. The arbitrator’s award shall be binding
                & may be entered as a judgment in any court of competent
                jurisdiction. If we don't enforce any particular provision, we
                are not waiving our right to do so later. If any provision of
                this Agreement is held invalid, unenforceable, or void by a
                court of competent jurisdiction, then this Agreement shall be
                considered divisible as to such provision, and the remainder of
                the Agreement shall be valid and binding as though such
                provision were not included in this Agreement. We may send
                notices to You through the email address You provide, or by
                certified mail. Notices sent by certified mail will be deemed
                received five days following the date of mailing. We may update
                this Agreement at any time, with updates taking effect when You
                next use the Service or after 30 days, whichever is sooner. No
                other amendment to this Agreement will be effective unless
                posted at Our Service. Headings are for reference purposes only
                and in no way define, limit, construe or describe the scope or
                extent of such action.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">20. Force Majeure.</h2>
              <p>
                If by reason of failures of telecommunications or internet
                service providers, labor disputes, riots, inability to obtain
                labor or materials, earthquake, fire or other action of the
                elements, accidents, governmental restrictions or other causes
                beyond Our control We are unable to perform in whole or in part
                its obligations as set forth in this Agreement, then the Company
                and the Service shall be relieved of those obligations to the
                extent it is so unable to perform and such inability to perform
                shall not make the Company or the Service liable to You.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                21. Relationship of the Parties.
              </h2>
              <p>
                Nothing contained in this Agreement shall be construed as
                creating any agency, legal representative, partnership, or other
                form of joint enterprise between the parties. Neither party
                shall have authority to contract for or bind the other in any
                manner whatsoever.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                22. Messages Transmitted Through Electronic Mail.
              </h2>
              <p>
                Notwithstanding any of the foregoing, if You voluntarily provide
                your E-mail address for public distribution or for any other
                purpose to Us then You agree to receive unsolicited
                advertisement or promotional message from Us or its agents, or
                affiliates, or associated third parties. If You want to opt out
                then You must contact Us at terms@delivahere.com.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                23. Security Procedures.{" "}
              </h2>
              <p>
                DelivaHere LLC undertakes reasonable efforts to operate secure
                data networks which are protected by industry standard firewall
                and password protection systems. DelivaHere LLC undertakes
                reasonable efforts to review its security and privacy policies
                on a periodic basis and it may adjust and change the systems as
                necessary. Notwithstanding the above, and although DelivaHere
                LLC is ever diligent in its security pursuits, it cannot
                guarantee the success of its efforts.{" "}
              </p>

              <h2 class="heading3 text-black mb-2 mt-2">24. Privacy Policy:</h2>
              <p>
                SEE ADDITIONAL PRIVACY POLICY TERMS ATTACHED HERETO or at
                www.delivahere.com/privacy:DelivaHere LLC is committed to making
                your experience satisfying and safe. Protecting the privacy of
                our users is a primary concern for us. When you request
                DelivaHere LLC’s online services, the information you provide to
                us is used only to provide our creative entertainment services
                to deliver email notification to you (or to people you designate
                as your recipients) and from time to time, to validate your
                identity. Although a certain limited amount of information is
                required for us to be able to serve you our data collection
                policies otherwise allow you to choose how much information you
                provide to us. The information you provide will be shared only
                with our professional consultants.{" "}
                <strong>
                  {" "}
                  If user does not accept this privacy policy (including any
                  updated terms hereto) or does not meet or comply with these
                  provisions, then user may not use the Service. Service terms
                  and conditions are incorporated herein by reference and user
                  by using the Service hereby agrees that user shall comply with
                  Service terms and conditions and privacy policy. Please be
                  advised that DelivaHere LLC reserves the right to release such
                  information to law enforcement or other governmental officials
                  if in DelivaHere LLC's sole and absolute discretion, it is
                  deemed necessary to comply with the law.
                </strong>
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                25. Service Terms and Conditions
              </h2>
              <p>
                The Terms and Conditions set forth on the Service shall govern
                any claim relating to this Privacy Policy and will otherwise be
                deemed applicable to it.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                26. Corporate Transfers of Information.
              </h2>
              <p>
                Information about the Users of the Service, which include the
                User’s Personal Information, may be disclosed in association
                with certain business dealings such as any debt financing,
                acquisition or merger, sale of assets and in the event of a
                bankruptcy, assignment for benefit of creditors or receivership
                in which particular information could be sold or transferred to
                other parties as an asset. By using the Service and/or entering
                his Personal Information, each User consents to the Service’s
                use of his information as outlined in this Privacy Policy.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">27. Contributions.</h2>
              <p>
                Please send questions, comments or complaints (“Contributions”)
                to terms@delivahere.com. By submitting ideas, suggestions,
                documents or proposals to DelivaHere LLC through its suggestions
                or feedback webpages, you acknowledge and agree that: (i) your
                Contributions do not contain confidential or proprietary
                information; (ii) DelivaHere LLC is not under any obligation of
                confidentiality, express or implied, with respect to the
                Contributions; (iii) DelivaHere LLC shall be entitled to use or
                disclose (or choose not to use or disclose) such Contributions
                for any purpose, in any way, in any media worldwide; (iv)
                DelivaHere LLC may have something similar to the Contributions
                already under consideration or in development; (v) you
                irrevocably non-exclusively license to DelivaHere LLC rights to
                exploit your Contributions; and (vi) you are not entitled to any
                compensation or reimbursement of any kind from DelivaHere LLC
                under any circumstances.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                28. Applicable law and Jurisdiction
              </h2>
              <p>
                This Agreement shall be governed by the laws of the State of
                Delaware (without regard to the laws that might be applicable
                under principles of conflicts of law) as to all matters,
                including, but not limited to, matters of validity,
                construction, effect and performance. Should a lawsuit be
                necessary to enforce this Agreement the parties agree that
                jurisdiction and venue shall reside in the the State of Delaware
                in the County of the principal office of DelivaHere LLC. Both
                parties consent to the personal jurisdiction of the courts of
                the State of New Jersey.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                29. Entire Agreement.
              </h2>
              <p>
                You agree that These Terms of Use and the other policies posted
                on the Service constitute the entire, complete and exclusive
                agreement between The Company, the Service and You, superseding
                any prior agreements and understandings, whether written or
                oral, or whether established by custom, practice, policy or
                precedent with respect to the subject matter of this Agreement.{" "}
              </p>

              <h1 class="heading overflow-hidden text-center mx-auto mb-0">
                DelivaHere LLC
                <span class="d-block">DelivaHere </span>
              </h1>
              <h2 class="headingh2 text-center mx-auto mb-4">
                Additional Terms & Conditions - Package Delivery
              </h2>
              <p>
                Please, read the following additional terms and conditions
                carefully. It contains specific information regarding DelivaHere
                system, also known as DelivaHere, a product of DelivaHere LLC,
                and any other associated services, or associated websites owned
                and/or operated by <strong>DelivaHere LLC</strong> (collectively
                hereinafter the “Service”); and to any correspondence by e-mail
                between Us and You. The mobile application may at times be
                individually referred to as “Application” and the website may at
                times be individually referred to as “Website.” These additional
                Terms and Conditions are supplemental to the general DelivaHere
                LLC Terms and Conditions detailed earlier in this document.
                Please, read these terms carefully before using the Service.
                Using the Service indicates that You accept these terms. If You
                do not accept these terms, do not use the Service. These Terms
                of Use are legally enforceable and binding agreement between You
                and DelivaHere LLC and shall be referred to as “Agreement”’ or
                “Terms of Use.”
              </p>
              <ul class="list-number">
                <li>
                  <strong>a. Intended Use of the Service. </strong>DelivaHere is
                  a social, mobile system that offers an electronic marketplace
                  connecting people to transact business, primarily for package
                  delivery. You agree to only use the application for the
                  purposes for which it is intended. You shall not use it for
                  any purpose that is illegal or not in compliance with local,
                  national or international laws.{" "}
                </li>
                <li>
                  <strong>
                    b. Not a Delivery Service. Technology for Electronic
                    Marketplace.{" "}
                  </strong>
                  The user agrees neither DelivaHere LLC nor its affiliates
                  provide delivery services. DelivaHere is an electronic
                  marketplace technology operated by DelivaHere LLC that
                  connects Customers (Package Senders), Delivery Agents and
                  Processing Agents. Delivery Agents and Processing Agents that
                  are included in the Service are Independent Contractors acting
                  independent of DelivaHere LLC who choose to use DelivaHere
                  LLC’s technology to seek and fulfill delivery requests for
                  other users of the technology.{" "}
                </li>
                <li>
                  <strong>c. Damage to Package. </strong>Except to the extent
                  paid in settlement from any applicable insurance policies if
                  the Customer (Sender) opts for an insurance coverage, each
                  party agrees to indemnify and hold harmless the other Party,
                  and its respective directors, shareholders, affiliates,
                  officers, agents, employees, and permitted successors and
                  assigns against any and all claims, losses, damages,
                  liabilities, penalties, punitive damages, expenses, reasonable
                  legal fees and costs of any kind or amount whatsoever, which
                  result from or arise out of any act or omission of the
                  indemnifying party, its respective directors, shareholders,
                  officers, agents, employees and permitted successors and
                  assigns that occurs in connection with the transactions
                  supported through the mobile application or this Agreement.
                  This indemnification will survive the termination of this
                  Agreement. This term shall include, specifically cases of
                  inadvertent damage or loss of property during transactions
                  facilitated by the Service.{" "}
                </li>
                <li>
                  <strong>d. Payments – Customer (Sender) Fees. </strong> As
                  Customer (Sender), you agree to pay applicable fees, presented
                  through the app as required payment, for the use of the
                  Service to facilitate delivery of your package(s). The fees
                  may include any applicable taxes. You agree to pay any
                  applicable penalties that might arise as additional charges
                  from the Bank, either from unsuccessful payments due to
                  insufficient funds, returns, or other unplanned incidentals
                  related to the said payment.{" "}
                </li>
                <li>
                  <strong>e. Refunds. </strong>As customer (sender) you agree
                  that payment refunds shall only be made to you in a case when
                  a transaction was canceled in a timely manner, prior to the
                  time the delivery service is scheduled to begin. As an
                  example, a customer (sender) that cancels a delivery
                  transaction will be eligible to a refund if the sender cancels
                  the request prior to agreeing to and accepting a bid for that
                  transaction that was offered by a Delivery Agent (DA). If, on
                  the other hand, the customer (sender) cancels the transaction
                  after the point in the process when the delivery service is
                  planned to commence, that is, the time when the sender agreed
                  to with the Delivery Agent in a bid for the package delivery,
                  then a minimal transaction fee will be charged for processing
                  and administrative efforts incurred and only the balanced will
                  be refunded to the Customer.{" "}
                </li>
                <li>
                  <strong>f. Agent Commission. </strong>As an Agent, you agree
                  you shall receive as commission a percentage of the total
                  amount paid by the customer for a delivery transaction for
                  which you are a co-facilitator, acting either in the capacity
                  of the Delivery Agent (DA) or the Processing Agent (PA). The
                  rate of the Commission payable to you shall be as agreed at
                  the time you were activated as an Agent in the DelivaHere app.{" "}
                </li>
                <li>
                  <strong>g. Ratings. </strong>As a User, you agree that other
                  users that are party to a transaction within the Service may
                  voluntarily offer ratings and feedback regarding their
                  experience with the way and manner you performed your portion
                  of the delivery transaction. In this regard, a Customer
                  (Package Sender) may offer ratings and feedback about a
                  Delivery Agent and/or a Processing Agent. A Delivery Agent may
                  offer ratings and feedback about a Customer (Package Sender)
                  and/or a Processing Agent. A Processing Agent may offer
                  ratings and feedback about a Customer (Sender) and/or a
                  Delivery Agent. You further agree that DelivaHere LLC may use
                  the ratings and feedback provided in the Service in any manner
                  in relation with the operations of the Service without your
                  approval. You agree DelivaHere LLC does not have any
                  obligations to verify feedback but reserves the right to
                  remove any comments determined to include obscenity, offensive
                  language and personally identifiable information (PII) or
                  other data that might violate privacy and other applicable
                  local or international laws.{" "}
                </li>
                <li>
                  <strong>
                    h. Non-Performance of Responsibility in Service.{" "}
                  </strong>
                  You understand that each delivery transaction in the Service
                  involves a number of parties namely, Sender, Processing Agents
                  and Delivery Agent performing their various parts in a
                  sequence of activities for the delivery to be successful. You
                  understand that the delivery transaction will not be
                  successful if you or any one of the parties in the transaction
                  does not perform her/his part. You agree that if you do not
                  perform your part, other parties involved in the transaction
                  may take necessary action, as is allowed by the Service, up to
                  and including cancellation of the entire transaction, in order
                  to recover from the failed transaction. You agree that you
                  might be assessed as transaction fee following such
                  cancelation due to non-performance of your part in the
                  transaction. You further understand that DelivaHere LLC will
                  track Non-Performance by users (Sender, Processing Agents and
                  Delivery Agent) and reserves the right to penalize repeat
                  occurrence. Penalties could include, but not limited to,
                  suspension of your account for a while and in cases where the
                  issue persists, a total ban from the Service.{" "}
                </li>
                <li>
                  <strong>i. Insurance Opt-in. </strong>You agree that if an
                  Insurance coverage option is available to you at the time of
                  requesting a delivery transaction, you acknowledge you have
                  the option to opt-in for insurance coverage to protect against
                  damage or loss of your package. The user acknowledges that
                  DelivaHere LLC is not the Insurance agency or provider and
                  only serves as the integrator to connect the user to the
                  insurance agency. The specific terms and conditions for the
                  Insurance policy is provided to the User at the time of Opt-in
                  by the insurance agency, through DelivaHere LLC.{" "}
                </li>
                <li>
                  <strong>
                    j. Package Content & Customer (Sender) Responsibility.{" "}
                  </strong>
                  As a Customer (Sender) you acknowledge you retain ownership of
                  the package even as it is processed by the Processing Agent
                  (PA) and transported by the Delivery Agent (DA) for delivery
                  on your behalf. You agree, as the sender of the package that
                  you are aware of the contents of the package being dropped off
                  for delivery processing by the PA and transportation by the
                  DA. You further covenant you have not included any items
                  considered illegal or contraband by applicable local or
                  international laws. You agree to be responsible for the
                  contents you drop off for processing, transportation and
                  delivery to a recipient.{" "}
                </li>
                <li>
                  <strong>
                    k. Package Content & Delivery Agent Responsibility.{" "}
                  </strong>
                  As Delivery Agent, you agree you are not the owner of the
                  package but will have custody of the package you are
                  transporting for delivery on behalf of a customer (sender). As
                  Delivery Agent, you agree that you are fully aware of the
                  contents of the package you are accepting for delivery. As
                  Delivery Agent, you agree to deliver the package in person.
                  You further covenant not to remove from, add to, manipulate,
                  deface, enhance, reshape, reconstruct, or in any way tamper
                  with the contents of the package you will be transporting for
                  and delivering on behalf of the customer (sender). You hereby
                  agree to transport and deliver the package for delivery.{" "}
                </li>
                <li>
                  <strong>
                    l. Package Content & Processing Agent Responsibility.{" "}
                  </strong>
                  As Processing Agent, you agree you are not the owner of the
                  package but will have custody of the package you are
                  processing for delivery on behalf of a customer (sender). As
                  Delivery Agent, you agree that you are fully aware of the
                  contents of the package you are accepting for delivery
                  processing. As Processing Agent, you further covenant not to
                  remove from, add to, manipulate, deface, enhance, reshape,
                  reconstruct, or in any way tamper with the contents of the
                  package you will be processing on behalf of the customer
                  (sender). You hereby agree to process the package for delivery
                </li>
                <li>
                  <strong>m. Package Restrictions - Sender. </strong>As sender
                  you covenant you will not include items considered illegal or
                  contraband by applicable local or international laws in a
                  package you drop off for delivery. You agree that the
                  Processing Agent or Delivery Agent can decline to continue
                  with the delivery transaction if any content of the package is
                  determined to be illegal or not allowed for local of
                  international transportation. You agree to be responsible for
                  the contents you have dropped off for delivery.{" "}
                </li>
                <li>
                  <strong>n. Media Content Captured by Customer. </strong>As
                  Customer (Sender), you agree the photos and videos you have
                  captured to describe a package and its contents are true
                  representations of the item to be transported for delivery.{" "}
                </li>
                <li>
                  <strong>
                    o. Media Content Captured by Processing Agent.{" "}
                  </strong>
                  As Processing Agent, you agree the photos and videos you have
                  captured to describe a package and its contents are true
                  representations of the item delivered to you for processing
                  for the purpose of delivery.{" "}
                </li>
              </ul>
              <h1 class="heading overflow-hidden text-center mx-auto mb-0">
                DelivaHere LLC
              </h1>
              <h2 class="headingh2 text-center mx-auto mb-4">
                Service Privacy Policy
              </h2>
              <h2 class="heading3 text-black mb-2 mt-2">1. Introduction.</h2>
              <p>
                DelivaHere LLC, a Delaware Company (hereinafter, “We,” “Our,” or
                “Us”), is committed to protecting the privacy of its Users (as
                defined herein below). This Privacy Policy (hereinafter,
                "Privacy Policy") outlines what information DelivaHere LLC
                collects and uses from its Users through the mobile application,
                its website
                <a
                  href="http://www.delivahere.com/"
                  target="_blank"
                  className="ml-1"
                >
                  www.delivahere.com
                </a>
                , and other products and services offered by DelivaHere LLC
                (collectively hereinafter the "Service"). The mobile application
                may at times be individually referred to as “Application” and
                the website may at times be individually referred to as
                “Website.” DelivaHere LLC will only keep Users’ information,
                which they submit through the Service for internal use. If any
                provision of this Privacy Policy conflicts with one or more
                provisions of Service Terms and Conditions, the provisions of
                Service Terms and Conditions shall control.{" "}
                <strong>
                  If User does not accept this Privacy Policy (including any
                  updated terms hereto) or does not meet or comply with these
                  provisions, then User may not use this Service. Service Terms
                  and Conditions is incorporated herein by reference and User by
                  using the Service hereby agrees that User shall comply with
                  Service Terms and Conditions and Privacy Policy. Please be
                  advised that DelivaHere LLC reserves the right to release such
                  information to law enforcement or other governmental officials
                  if in DelivaHere LLC's sole and absolute discretion, it is
                  deemed necessary to comply with the law.
                </strong>
              </p>
              <p>
                <strong>
                  If you are a user accessing the Service from the European
                  Union, Asia, Africa, Americas or any other region with laws or
                  regulations governing personal data collection, use, and
                  disclosure that differ from United States laws, please be
                  advised that through your continued use of the Website or
                  Application which are governed by united states law, this
                  privacy policy, and our end user license agreement, you may be
                  transmitting your personal information to countries (including
                  the united states) where laws regarding processing personal
                  information may be less stringent than processing of personal
                  information in your country and you hereby consent to such
                  transmission of personal information.{" "}
                </strong>
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                2. Revisions to Privacy Policy.
              </h2>
              <p>
                DelivaHere LLC reserves the right to revise, change, amend,
                alter or modify (hereinafter, "Update") this Privacy Policy and
                its other policies and agreements at any time and in any manner.
                The User, as defined below, is responsible for periodically
                checking for any Updates of this Privacy Policy. We may also
                notify you of any changes to our Privacy Policy by posting the
                new Privacy Policy on our Service at
                <a
                  href="http://www.delivahere.com/privacy"
                  target="_blank"
                  className="ml-1"
                >
                  www.delivahere.com/privacy
                </a>{" "}
                and/or via email. Any Updates supersede any prior versions
                immediately upon posting on the Service. Using the Service after
                any such Update or changes to the Privacy Policy shall
                constitute consent to changed terms or practices.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                3. Information Collected.
              </h2>
              <p>
                The information which DelivaHere LLC collects through the
                Service may include Personal Information (hereinafter the
                "Personal Information") which refers to information that helps
                it identify users or viewers of the Service (collectively,
                "Users" and each a "User" or “You” and shall refer to Registered
                User (as defined herein below). The Personal Information
                includes but shall not be limited to data such as a User’s name,
                street address, phone number, facsimile number, email address,
                username and password. Users are responsible for keeping all
                Personal Information current and may change any previously
                entered information through the Service in the manner set forth
                below. DelivaHere LLC may utilize Personal Information, without
                limitation, for the following purposes: (i) establishing and
                verifying user identities; (ii) opening, maintaining,
                administering and servicing Registered User accounts; (iii)
                processing, servicing or enforcing transactions and sending
                communications relating to the Service; (iv) providing User
                support in connection with User-use of the Service; (v)
                providing updates for the Service; (vi) providing promotional
                notices and offers and other DelivaHere LLC information; (vii)
                responding to User-posed inquiries and comments; (viii)
                maintaining the security of the Service and Company-systems; and
                (ix) evaluating Use of the Service for potential improvements
                and otherwise. The Service may also collect data that cannot be
                traced back to a specific individual (hereinafter the "Anonymous
                Information"). For example, DelivaHere LLC may be able to keep
                count of how many Users have viewed the Service and specific
                content therein, or key words utilized to find the Service, but
                this information does not necessarily include information
                regarding Users names, street addresses, phone numbers or email
                addresses. Users who view the Service but do not enter their
                Personal Information are generally anonymous Users. Only
                Anonymous Information and no Personal Information will be
                intentionally collected by DelivaHere LLC from anonymous Users.
                The Service may use browser cookies to collect Anonymous
                Information. The Service may also incorporate web beacons or
                similar tracking technologies to allow DelivaHere LLC to track
                how the Service is used. Such technologies are used to collect
                Anonymous Information, e.g. the name of the User’s internet
                service provider, the IP address of the User’s computer, User’s
                browser software and operating system, the identity of any
                linked-form or linked-to website and other similar information.
                Furthermore, DelivaHere LLC may, either directly or through a
                third party, track Users’ conduct on the Service, including,
                without limitation, areas of the Service visited, topic(s)
                viewed, most popular pages of the Service, Users’ Internet
                connection type and browser use DelivaHere LLC may combine a
                User’s Anonymous Information with similar information collected
                from other Users to help improve the Service.{" "}
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">3.1.</strong> We disclaim any
                liability for disclosure of any Personal Information or
                non-Personal Information arising out of any of the following
                circumstances:
              </p>
              <ul class="list-number">
                <li>
                  1. Disclosure to government (local, state, national, or
                  international) agencies in response to legal processes, such
                  as subpoenas or court order; or{" "}
                </li>
                <li>
                  2. Any unlawful or unauthorized access to and transmissions of
                  Personal Information from the Service; or
                </li>
                <li>
                  3. Disclosure of any information by the User themselves on
                  message boards, public forums, blogs, social networking
                  websites, or any other online or offline media.{" "}
                </li>
              </ul>
              <p class="d-flex">
                <strong class="me-1 min-30">3.2.</strong>The use of some
                features of the Service requires the User to register using a
                simple registration form. Through the form, Personal Information
                is collected and additionally Personal Information is collected
                at different stages and at different times of the Users use of
                this Service. This Personal Information may include, but shall
                not be limited to: the User's name, Company name, physical
                addresses, email address, telephone number, fax no., credit card
                information or other financial/transactional information,
                relationship status, place of study, employer, gender,
                photographs, video recordings, audio recordings, text notes,
                contact information, etc. While collecting such Personal
                Information from the Users, we take great care and diligence in
                collecting only as much information as is absolutely vital to
                provide the User with efficient and timely services. All such
                Personal Information is kept confidential and never shared with,
                or sold to, or traded with, any third party without your
                explicit consent.{" "}
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">3.3.</strong>Some of the instances
                when we collect Personal Information from the Users of this
                Service include:
              </p>
              <ul class="list-number">
                <li>1. during registration process; or </li>
                <li>
                  2. while subscribing to our different products and/or
                  features; or
                </li>
                <li>
                  3. while completing an online form to request information
                  about our products and/or services.{" "}
                </li>
                <li>4. When you use the blog or contact us features</li>
              </ul>
              <p class="d-flex">
                <strong class="me-1 min-30">3.4.</strong>Some of the uses of the
                Personal Information so collected by our Service include:
              </p>
              <ul class="list-number">
                <li>
                  1. to allow the User to access special features reserved for
                  our registered members; or{" "}
                </li>
                <li>
                  2. to provide the User the information requested from us; or{" "}
                </li>
                <li>
                  3. to register the User for our seminars, forums, contests,
                  and online chats; or{" "}
                </li>
                <li>4. to contact the User when requested; or </li>
                <li>
                  5. to make purchases, respond to offers, or download features
                  from Service; or{" "}
                </li>
                <li>
                  6. to provide such information to our affiliates and partners
                  (if any) under confidentiality agreement so that they may
                  provide the User with offers and information about products or
                  services the User might be interested in.{" "}
                </li>
              </ul>
              <p>
                <div class="d-flex">
                  <strong class="me-1 min-30">3.5.</strong>The User can always
                  disallow us to contact the User regarding the latest offers,
                  news, and updates, by opting out at the time of registration
                  or by sending an email to
                </div>{" "}
                <strong class="d-inline-block min-30 ml-space3"></strong>
                <a href="mailto:terms@delivahere.com" target="_blank">
                  {" "}
                  terms@delivahere.com{" "}
                </a>
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">3.6.</strong>Besides the
                above-mentioned Personal Information, some non-Personal
                Information may be collected when the User accesses the Service.
                Such non-Personal Information may include the type of browser
                the User used to access the Service, the operating system(s)
                installed on the User’s computer, internet protocol (ip) address
                of the User’s computer, ip address of the User’s internet
                service provider (isp), etc. When you use a mobile device, the
                following information is automatically collected: the type of
                mobile device used, the mobile device's unique device ID, the
                mobile operating system of the device, the type of mobile
                internet browser used, and information about the way You use the
                Service. This information is generally used to improve the
                layout and content of our Service, provide the User with
                customized advertisements, analyze the usage of the Service,
                dispense with the need to re-login every time the User visits
                and logs into the Service, and enhance the overall experience of
                using the Service. All such non-Personal Information is kept
                confidential and never shared with, or sold to, any third party.
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">3.7.</strong>{" "}
                <span>
                  <strong>Cookies.</strong> Cookies are small text files that
                  are placed on the Users hard disk by our secured servers.
                  These cookies are used to collect non-Personal Information.
                  Cookies help Us in gathering information, like how the User
                  uses our Service, how the User uses our services, types of
                  data accessed, times and volume of use, duration of sessions
                  and other similar usage. Our cookies are safe, and do not, in
                  any way, compromise the User’s privacy while the User is using
                  our Service. Third party vendors may also use cookies to serve
                  advertisements on our Service. A User can also disable the
                  cookies by adjusting the internet browser preferences on their
                  computer/electronic devices. However, we advise you to receive
                  cookies sent by our Service. By visiting or using our Service
                  you consent to the placement of cookies in your browser in
                  accordance with this Privacy Policy.
                </span>
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">3.8.</strong>We accord maximum
                priority to the security of all the data we collect from the
                Users and/or Registered Users of this Service. Therefore, we use
                the latest and most secure technologies prevalent in the
                industry and available in the market to protect the integrity of
                all the Personal Information or non-Personal Information
                collected during the course of your interaction with the
                Service. Apart from several electronic and technical safeguards,
                we also employ certain physical and procedural safeguards in
                order to make the access to this sensitive data exclusive to the
                authorized personnel have access to this data. Notwithstanding
                any amount of steps and assurances we give, due to peculiar
                nature of the Web, no foolproof security from unauthorized
                access by hackers, crackers, or similar illegal means and
                personnel, can be guaranteed. Additionally, while processing
                online payments, we do not retain or intercept any credit card
                data which you submit through our secure servers. All such data
                is directly sent to the secured servers of the credit card
                companies through latest SSL encryption method for further
                processing.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">4. Tag Feature.</h2>
              <p>
                This Privacy Policy governs DelivaHere LLC's Application and
                services developed for mobile devices. This application gives a
                User the ability to leave a digital footprint of his or her
                presence at any geographical location. The Application also
                provides the complementary means of detecting and viewing that
                digital print, this is known as a “Tag.” DelivaHere LLC allows
                Users to utilize their mobile devices to make video, audio,
                photo, and text recording of their experiences and associate
                them with the Tags they create. DelivaHere LLC Users can set
                three levels of privacy for their tags, these include “public,”
                “circle,” and “private.” Public tags can be detected and viewed
                by any other DelivaHere LLC User. Circle tags can only be
                detected and viewed by those in the tag owner's circle of
                friends. Private tags limits access to the tag owner. Please
                review Our “How To” guide to learn more about how to use and set
                tag privacy.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                5. Registered Users.
              </h2>
              <p>
                A User may become a registered member of the Service in
                accordance with the Service Terms and Conditions (hereinafter
                the "Registered User"), in which case the User may be asked to
                provide the following information: Personal Information,
                including his or her name, address, telephone number, email
                address, a unique login name, password and password validation,
                a password hint to help the User remember his password. This
                information is collected within the registration process, in
                order to allow for greater ease in the Registered User utilizing
                the Service and its interactive features; to allow DelivaHere
                LLC and the Service to identify the Registered User; and to
                allow DelivaHere LLC to contact the Registered User for customer
                service purposes and otherwise. In addition, DelivaHere LLC
                requests Registered User’s email address to confirm new member
                registration and to enable email or other electronic
                communications to be made through the Service. Moreover,
                DelivaHere LLC may utilize a Registered User’s email address to
                send updates, newsletters, news or other information regarding
                the Service to the Registered User. In the event that Registered
                User no longer wishes to receive such emails, Registered User
                may "opt-out" by contacting DelivaHere LLC by sending an email
                at terms@delivahere.com. The subject line of that communication
                should read: "DO NOT SEND ME NOTIFICATION EMAILS." Registered
                Users may be providing more Personal Information than general
                Users to the Service. By becoming a Registered User that User
                assumes all risk, and agrees to these terms with regard to all
                of the information that he submits vis-à-vis the Service.
                Furthermore, the information set forth in the Registered User’s
                profile may be shared with others, and registration as a
                Registered User constitutes consent to sharing this information.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                6. User Posted Content.
              </h2>
              <p>
                Users may post content, including, without limitation, on the
                Service that may be made publicly available to other users and
                to the general public via the Service. Users acknowledge and
                agree that any posted comments posted by User do not reflect the
                Service or DelivaHere LLC Users acknowledge and agree that by
                posting or otherwise submitting such content on or to the
                Service, User’s Personal Information, including his email
                address and any other information the User supplies may be
                visible to the third parties. User shall not share any
                information in his inquiry that he is not prepared to be public
                or to otherwise share with such other third parties. Further,
                Users may submit inquiries, comments or other content
                (collectively, an "Inquiry") to DelivaHere LLC through the
                Service. Users acknowledge and agree that by sending an Inquiry
                or otherwise posting communications to the Service, a User’s
                Personal Information, including his email address and any other
                information the User supplies, may be visible to third parties.
                User shall not share any information in his Inquiry that he is
                not prepared to be public or to otherwise share with such other
                third parties. Moreover, User’s Inquiry may be available for
                review by DelivaHere LLC and/or other Users’ contractors and
                employees. Moreover, DelivaHere LLC may from time to time, use
                third party email servers to send and track receipt of the
                Inquiries, and analyze the pattern of inquiry usage via these
                third party tracking systems. If a User is making an Inquiry or
                posting content for someone other than himself, in which another
                person’s Personal Information will be accessible or otherwise
                made available, User hereby makes the material representation
                and warranty upon which he wishes DelivaHere LLC to reasonably
                rely, that he has obtained each of those other individuals’
                consent to disclose their personal information to DelivaHere LLC
                and other Users. All submissions of content, including without
                limitation, the posting of content to the Service or the
                submission of an Inquiry, sent to DelivaHere LLC are accepted in
                accordance with, and governed by, the Service Terms and
                Conditions. Moreover, User acknowledges and agrees that this
                Privacy Policy does not apply to content, business information,
                pictures, ideas, concepts or inventions that User sends to
                DelivaHere LLC (collectively, "Submitted Property"). User
                acknowledges and agrees that all Submitted Property becomes the
                property of DelivaHere LLC immediately upon its receipt of the
                same, unless DelivaHere LLC elects not to take ownership of said
                property. DelivaHere LLC may then utilize, distribute or
                otherwise disseminate the Submitted Property as it wishes in its
                sole discretion. As it relates to intellectual property rights
                relating to the same, User hereby assigns and conveys all rights
                he may have to such submitted property and ideas. If User seeks
                to keep such information or property private or proprietary, he
                should not send such information to DelivaHere LLC.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                7. Third Party Advertisers and Links to Third-Party Websites.
              </h2>
              <p>
                The Service may include advertisements, and offers of products
                and services from third-party companies which may include links
                to third-party websites. The advertisements and offers placed by
                these companies, or other listings of other Users are not
                affiliated, owned, maintained or updated by DelivaHere LLC. If
                User visits the website of these third-party advertisers, or
                otherwise submits information to any other User, they may use
                cookies and/or request and collect User’s Personal Information.
                DelivaHere LLC does not know of the policies of these
                independent third-party advertisers, including without
                limitation, other Users, and is not responsible for the
                information collected or how the information is used by any
                third-party advertisers, including without limitation, other
                Users. DelivaHere LLC also cannot confirm or in any way
                guarantee the accuracy of the information provided by those
                third-party advertisers on their websites. DelivaHere LLC
                strongly recommends that User reads and reviews the terms and
                conditions, and privacy policies of any other third-party
                websites that it may view. The Service may contain links to
                third-party websites of promoters, advertisers, affiliates,
                partners, agents, etc. However, we do not draft, review, control
                or monitor the privacy practices of such third-party websites.
                Should the User choose to visit these linked websites, the
                User’s visit and/or use of such linked websites shall be subject
                to the privacy policies of such third-party websites. We
                strongly advise you to review the legal documents of such linked
                websites before entering into any transaction.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                8. Information Sharing.
              </h2>
              <p>
                Except as otherwise provided in this Privacy Policy, DelivaHere
                LLC will not intentionally distribute, rent or sell a User’s
                Personal Information, User posted content, or other information
                collected from the User to third parties without the User’s
                permission. Notwithstanding the foregoing, DelivaHere LLC may be
                required by law enforcement or judicial authorities to provide
                Users’ Personal Information to the appropriate governmental
                authorities. If DelivaHere LLC receives a request from law
                enforcement or judicial authorities, then it reserves the right
                to provide the requested information. In addition, DelivaHere
                LLC reserves the right to release such information to law
                enforcement or other governmental officials as, in DelivaHere
                LLC’s sole and absolute discretion, it is deemed necessary to
                comply with the law. DelivaHere LLC may also disclose such User
                information to Our trusted service providers who work on Our
                behalf and have agreed to abide by the terms of this Privacy
                Policy. DelivaHere LLC may also disclose such User information
                to Our trusted third parties who assist us in providing services
                to and operating the Website and Application, or otherwise
                conducting our business, so long as those parties agree to keep
                this information confidential. DelivaHere LLC may also disclose
                such non-personally identifiable User information to other
                parties for marketing, advertising or other uses.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                9. Security Procedures.
              </h2>
              <p>
                DelivaHere LLC undertakes reasonable efforts to operate secure
                data networks which are protected by industry standard firewall
                and password protection systems. DelivaHere LLC undertakes
                reasonable efforts to review its security and privacy policies
                on a periodic basis and it may adjust and change the systems as
                necessary. All supplied sensitive/credit information is
                transmitted via Secure Socket Layer (SSL) technology and only is
                only accessible to those authorized with special access rights
                to such systems; such individuals are further required to keep
                this information confidential. After any financial transaction
                on Service, all the Users private information (credit cards,
                social security numbers, and any other related personal or
                financial information) shall not be stored on our servers.
                Notwithstanding the above, and although DelivaHere LLC is ever
                diligent in its security pursuits, it cannot guarantee the
                success of its efforts. Note: DelivaHere LLC servers used to
                store your personal information owned and hosted by Amazon Web
                Services LLC, which is an Amazon.com company. For more
                information on steps taken by Amazon to protect the security
                interest of their servers and Your personal information please
                review the following:{" "}
              </p>
              <p class="text-center">
                <a
                  href="http://awsmedia.s3.amazonaws.com/pdf/AWS_Security_Whitepaper.pdf"
                  target="_blank"
                >
                  http://awsmedia.s3.amazonaws.com/pdf/AWS_Security_Whitepaper.pdf
                </a>
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">10. Special Cases.</h2>
              <p>
                It is DelivaHere LLC's policy to not use or share the Personal
                Information about the Users of the Service in ways which have
                not been outlined or described herein without first allowing its
                Users a chance to opt out or otherwise disallow such unrelated
                uses. However, DelivaHere LLC may disclose Personal Information
                about its Users or information relating to their Use of the
                Service for any reason if, in its sole discretion, it determines
                that it is reasonable to do so, including:{" "}
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">10.1.</strong>To comply with laws,
                such as the Electronic Communications Privacy Act, regulations
                or governmental or legal requests for such information;
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">10.2.</strong>To reveal information
                that is needed to identify, contact or bring legal action
                against any User who may be violating the Service Terms and
                Conditions;{" "}
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">10.3.</strong>To operate the Service
                properly;{" "}
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">10.4.</strong>To protect the Service
                and its Users; and/or{" "}
              </p>
              <p class="d-flex">
                <strong class="me-1 min-30">10.5.</strong>To fully cooperate
                with law enforcement authorities, private-party litigants and
                others seeking information about its Users to the extent
                required by applicable law.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                11. Managing and Deleting Users’ Information.
              </h2>
              <p>
                If a User would like to modify or delete any Personal
                Information, Non Personal Information, or User Posted Content
                previously entered on the Service, User can update User’s
                information by contacting DelivaHere LLC through email at
                terms@delivahere.com and providing User’s current and updated
                information. Notwithstanding the foregoing, a Registered User
                may be able to update Registered User’s Personal Information by
                changing his online profile through the Service. Once You delete
                your content, it will not appear on the Service, but copies of
                your deleted content may remain in our system or backups for
                some period of time. We will retain web server access logs and
                other collected information for a maximum of 36 months and
                thereafter may store it in the aggregate. Please note, some or
                all of the Personal Information, Non Personal Information, or
                User Posted Content may be required in order for the Service to
                function properly.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                12. Protection of Children.
              </h2>
              <p>
                The Service is directed at individuals who are at least 13 years
                old. We do not knowingly collect any information from or market
                to anyone under 13 years of age. If a parent or guardian becomes
                aware that his or her child has provided us with information
                without their consent, he or she should contact Us immediately
                at terms@delivahere.com. We will delete such information from
                our files within a reasonable time.
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">13. Contacting Us.</h2>
              <p>
                If Users have any questions about this Privacy Policy, please
                feel free to contact DelivaHere LLC by sending an email to
                terms@delivahere.com, www.delivahere.com/contact_us.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                14. Service Terms and Conditions.
              </h2>
              <p>
                The Service Terms and Conditions attached hereto or which can be
                found at www.delivahere.com/terms shall govern any claim
                relating to the Privacy Policy and will otherwise be deemed
                applicable to it.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                15. Online Privacy Policy Only.{" "}
              </h2>
              <p>
                This Privacy Policy applies only to information collected online
                through DelivaHere LLC's Website, Application, and any other
                associated online sites or applications, not to information
                collected offline.{" "}
              </p>
              <h2 class="heading3 text-black mb-2 mt-2">
                16. Corporate Transfers of Information.
              </h2>
              <p>
                Information about the Users of the Service, which include the
                Users’ Personal Information, may be disclosed in association
                with certain business dealings such as any debt financing,
                acquisition or merger, sale of assets and in the event of a
                bankruptcy, assignment for benefit of creditors or receivership
                in which particular information could be sold or transferred to
                other parties as an asset. By using the Service and/or entering
                his Personal Information, each User consent to the Service’s use
                of his information as outlined in this Privacy Policy. If any
                such business dealings occur which requires the transfer of
                Users' Personal Information or User Posted Content, User will be
                notified via email or on the Service.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Tnc;
