import Validator from "validator";
import isEmpty from "lodash/isEmpty";
import ValidationMessages from "../helpers/ValidationMessages";

function validateInput(data) {
  let errors = {};
  if (Validator.isEmpty(data.name)) {
    errors.name = ValidationMessages.firstName.required;
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = ValidationMessages.email.required;
  } else if (!Validator.isEmail(data.email)) {
    errors.email = ValidationMessages.email.invalid;
  }

  if (Validator.isEmpty(data.mobile)) {
    errors.mobile = ValidationMessages.phoneNumber.required;
  } else if (!Validator.isMobilePhone(data.mobile)) {
    errors.mobile = ValidationMessages.phoneNumber.invalid;
  }

  if (Validator.isEmpty(data?.country)) {
    errors.country = ValidationMessages.country.required;
  }

  if (Validator.isEmpty(data.city)) {
    errors.city = ValidationMessages.city.required;
  }
 
  if (data.roleId == '') {
    errors.role = ValidationMessages.role.required;
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export default validateInput;
