import axios from 'axios'
import ApiJson from './apiJson'
import {
  showErrorToast,
  showSuccessToast,
  getLocalStorage,
} from '../helpers/Utils'

let getAPIURL = () => {
  // DEV - 125.99.189.199:8711
  // QA - 125.99.189.199:8721
  // PROD - admin.delivahere.com
  // 18.116.106.155:8721
  let returnUrl = ''
  switch (window.location.hostname) {
    case 'localhost':
      returnUrl = 'http://125.99.189.199:8711' //localhost/dev
      break
    case '125.99.189.199':
      returnUrl = 'http://125.99.189.199:8721' //QA
      break
    default:
      returnUrl = 'https://admin.delivahere.com' //PROD
      break
  }
  return returnUrl
}

let apiFailCounter = 0
// axios.defaults.baseURL = 'http://103.21.53.12:8711/';
axios.defaults.baseURL = getAPIURL()

axios.interceptors.request.use(
  function (config) {
    let userInfo =
      // getLocalStorage('organizerInfo') || getLocalStorage('vendorInfo') ||
      getLocalStorage('adminInfo')

    if (userInfo) {
      // if (userInfo.access_token) {
      let accessToken = 'bearer ' + userInfo
      config.headers.Authorization = accessToken
      config.headers['Content-Type'] = 'application/JSON'
      config.headers.Accept = 'application/JSON'
      // }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const prepareDataObject = (_data_, paramObj) => {
  for (let key in _data_) {
    if (paramObj[key] || paramObj[key] === false) {
      _data_[key] = paramObj[key]
    } else {
      if (typeof _data_[key] !== 'object') _data_[key] = ''
    }
  }
  return _data_
}

const injectParamsToUrl = (_url_, paramObj) => {
  var url = _url_
  for (let key in paramObj) {
    url = url.replace(':' + key, paramObj[key])
  }
  return url
}

const handleErrorByStatus = (error) => {
  if (error && error.status != 200) {
    const message = error.data.error_description
    showErrorToast(message)
    if (error.status == 401) {
      localStorage.clear() //all items
      // window.location.reload();
    }
  }
}

const delivaApiService = (apiKeyName, data) => {
  let apiDetails = ApiJson[apiKeyName]
  if (!apiDetails) {
    throw new Error(
      'Api configuration do not found in api-json, please check api-json.js'
    )
  }

  let requestObject = Object.assign({}, apiDetails)
  requestObject.data = prepareDataObject(requestObject.data, data)
  requestObject.url = injectParamsToUrl(requestObject.url, data)

  return axios(requestObject)
    .then(function (result) {
      apiFailCounter = 0

      if (result.data) {
        // if (result.data && result.data.status === 'SUCCESS') {
        if (result.data.responseMessage) {
          const message = result.data.responseMessage

          if (requestObject.showResultMessage === true)
            showSuccessToast(message)
        }
      } else {
        handleErrorByStatus(result.data)
      }
      return result
    })
    .catch(function (error) {
      if (error && error.response) {
        if (requestObject.showErrorMessage === true)
          handleErrorByStatus(error.response)
      }

      if (
        error.config.maxContentLength - 1 &&
        error.toString().indexOf('Network Error') > -1
      ) {
        apiFailCounter++
        if (apiFailCounter >= 3) {
          localStorage.clear()
          window.open(window.location.origin, '_self')
        }
      }
      return error.response
    })
}

export default delivaApiService