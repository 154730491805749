import isEmpty from 'lodash/isEmpty'
//import ValidationMessages from '../helpers/ValidationMessages';

function validateInput(data) {
  console.log(data, 'dddddddddddddddd')
  let errors = {}

  if (data.bankVerification === false) {
    errors.bankVerification = 'Please verify bank details'
  }
  if (data.idProof == 'DL') {
    if (data.lisenceVerificationDA === false) {
      errors.lisenceVerificationDA = 'Please verify driving license details'
    }
  }
  if (data.idProof == 'PL') {
    if (data.passportVerification === false) {
      errors.passportVerification = 'Please verify passport details'
    }
  }
  if (data.idProof == 'NL') {
    if (data.nationalIdVerification === false) {
      errors.nationalIdVerification = 'Please verify national id details'
    }
  }

  if (data.idproofVerification === false) {
    errors.idproofVerification = 'Please verify id proofs'
  }

  if (data.timeVerification === false) {
    errors.timeVerification = 'Please verify operational hours'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export default validateInput
