import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import RejectIcon from "../../../assets/images/RejectIcon.svg";
import approved from "../../../assets/images/approved.svg";
import delivaApiService from "../../../common/services/apiService";
import validateInput from "../../../common/validations/validateDetailsPA";
import { showErrorToast } from "../../../common/helpers/Utils";

class ProcessingAgentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {},
      showRejectModal: false,
      passportVerification: false,
      bankVerification: false,
      timeVerification: false,
      idproofVerification: false,
      errors: {},
      rejectionReason: "Other",
      successModal: false,
      details: {},
      showLoader: false,
    };
  }

  handleReject = (userId) => {
    this.setState({
      showRejectModal: !this.state.showRejectModal,
      userId,
    });
  };

  componentDidMount() {
    if (this.props && this.props.location.state.item) {
      this.getProcessingAgentDetail();
    }
  }

  approveDA = (userId, name) => {
    if (this.isValid()) {
      this.setState({
        showLoader: true,
        daName: name,
        userId: userId,
      });
      delivaApiService("approveDA", { userId })
        .then((response) => {
          this.setState({ showLoader: false, successModal: true });
          if (response.data.status == 200) {
            this.getProcessingAgentDetail(userId);
            this.setState({
              errors: {},
            });
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          });
        });
    }
  };

  approvalSuccessModal = () => {
    this.setState({
      successModal: !this.state.successModal,
    });
    this.props.history.push('/verification/4')
  };

  onChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }

  getProcessingAgentDetail() {
    this.setLoader(true);
    delivaApiService("getProcessingAgentDetail", {
      id: this.props.location.state.item.userId,
    })
      .then((response) => {
        if (response && response.data) {
          this.setState({ details: response.data.resourceData });
        } else {
          this.setLoader(false);
        }
      })
      .catch((err) => {
        this.setLoader(false);
      });
  }

  operatingTime = (week) => {
    const { details } = this.state;
    var t_1 = "Closed";

    if (details?.operationalHours && details?.operationalHours.days) {
      const dayInfo = details.operationalHours.days.find(
        (item) => item.dayName === week
      );

      if (dayInfo) {
        if (dayInfo.open) {
          t_1 = `${dayInfo.startTime} - ${dayInfo.endTime}`;
        }
      }
    }

    return t_1;
  };

  rating = (rating) => {
    let drawRating = "";
    for (var i = 1; i <= 5; i++) {
      if (i <= rating) {
        drawRating += '<span className="icon-shapes-and-symbols"></span>';
      } else {
        drawRating += '<span className="icon-star"></span>';
      }
    }
  };

  isValid() {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    console.log(isValid);
    return isValid;
  }

  submitReject = () => {
    if (
      this.state.rejectionReason &&
      this.state.description &&
      this.state.userId
    ) {
      let data = {
        reasonForRejection: this.state.rejectionReason,
        rejectionDetail: this.state.description,
        userId: this.state.userId,
      };
      delivaApiService("rejectDA", data)
        .then((response) => {
          this.setState({ showLoader: false });
          if (response.data.status == 200) {
            this.setState({
              showRejectModal: !this.state.showRejectModal,
            });
            this.props.history.push("/verification/4");
            this.getProcessingAgentDetail(data.userId);
          }
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          });
        });
    } else {
      showErrorToast("Please write rejection details");
    }
  };
  render() {
    const { details, errors } = this.state;
    console.log("details", details);
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3 align-items-center">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal fs-24 mb-0 line-ht color-33">
                        Processing Agent
                      </h3>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <button className="yellow-text-btn fs20">
                        {details && Object.keys(details).length > 0 && (
                          <button
                            className={`${
                              details.rejected ? "color-red" : "color-blue"
                            } background-none border-none fs20`}
                          >
                            {details.rejected
                              ? "Rejected"
                              : details.active
                              ? "In-process"
                              : "Pending"}
                          </button>
                        )}
                        {/* {details.active ? "In-progress" : "pending"} */}
                      </button>
                    </Col>
                  </Row>
                  <div className="details-wrap mb-4">
                    <Row noGutters>
                      <Col md={12} lg={12} className="">
                        <h5 className="px-4">Personal Details</h5>
                        <hr />
                      </Col>
                    </Row>
                    <Row className="px-4">
                      <Col md={6} lg={3} className="">
                        <div className="pr-wrap">
                          <h5 className="font-weight-normal color-grey fs18">
                            Name
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {details.bankingDetails
                              ? details.bankingDetails.fullName
                              : ""}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Agent ID
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            #{details.userId}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Date of Registration
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {moment(
                              this.props.location?.state?.item?.createdDate
                            ).format("DD MMM YYYY")}
                          </h5>
                        </div>
                      </Col>
                      <Col md={6} lg={3} className="">
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Mobile No.
                          </h5>
                          {details.countryCode && details.mobile ? (
                            <h5 className="fs18 font-weight-normal">
                              +{details.countryCode}-{details.mobile}
                            </h5>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="px-4">
                      <Col md={6} lg={3}>
                        <h5 className="font-weight-normal color-grey fs18">
                          Email
                        </h5>
                        <h4 className="fs18 font-weight-normal">
                          {details.email}
                        </h4>
                      </Col>
                      <Col md={3} lg={3}>
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Name
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {details.businessName || ""}
                          </h5>
                        </div>
                      </Col>
                      <Col md={3} lg={3}>
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Business Type
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {details.businessName || ""}
                          </h5>
                        </div>
                      </Col>

                      <Col md={3} lg={3}>
                        <div className="">
                          <h5 className="font-weight-normal color-grey fs18">
                            Business Reg No
                          </h5>
                          <h5 className="fs18 font-weight-normal">
                            {details.businessRegistrationNumber || ""}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="px-4">
                      <Col md={6} lg={6}>
                        <h5 className="font-weight-normal color-grey fs18">
                          Address
                        </h5>
                        <h5 className="fs18 font-weight-normal">
                          {details.bussinessAddress &&
                          details.bussinessAddress.aptNumber
                            ? details.bussinessAddress.aptNumber + " "
                            : ""}

                          {details.bussinessAddress &&
                          details.bussinessAddress.address
                            ? details.bussinessAddress.address + ", "
                            : ""}

                          {details.bussinessAddress &&
                          details.bussinessAddress.city
                            ? details.bussinessAddress.city + ", "
                            : ""}

                          {details.bussinessAddress &&
                          details.bussinessAddress.state
                            ? details.bussinessAddress.state + ", "
                            : ""}

                          {details.bussinessAddress &&
                          details.bussinessAddress.country
                            ? details.bussinessAddress.country
                            : ""}
                        </h5>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-layout-update">
                    <Row className="mb-3">
                      {/* Passport details */}
                      <Col lg={6} className="">
                        <div className="details-wrap optional-hrs">
                          <Row noGutters>
                            <Col md={12} lg={12} className="">
                              <h5 className="px-4">Operational Hours</h5>
                              <hr />
                            </Col>
                          </Row>
                          <div className="row m-0">
                            <div className="col-sm-6">
                              <ul className="hrs-list">
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">Monday</span>
                                    </div>
                                    <div className="col-sm-7 text-right fw500">
                                      {this.operatingTime("Monday")}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Tuesday
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right fw500">
                                      {this.operatingTime("Tuesday")}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Wednesday
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right fw500">
                                      {this.operatingTime("Wednesday")}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Thursday
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right fw500">
                                      {this.operatingTime("Thursday")}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="col-sm-6">
                              <ul className="hrs-list">
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Friday{" "}
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right fw500">
                                      {this.operatingTime("Friday")}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">
                                        Saturday
                                      </span>
                                    </div>
                                    <div className="col-sm-7 text-right fw500">
                                      {this.operatingTime("Saturday")}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <span className="color-grey">Sunday</span>
                                    </div>
                                    <div className="col-sm-7 text-right fw500">
                                      {this.operatingTime("Sunday")}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <hr />
                              {details.active ? (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test1"
                                    name="timeVerification"
                                    checked
                                  />
                                  <label htmlFor="test2">Verified</label>
                                </div>
                              ) : (
                                <div className="deliva-radio text-center">
                                  <input
                                    type="checkbox"
                                    id="test1"
                                    name="timeVerification"
                                    value={this.state.timeVerification}
                                    onChange={this.onChange}
                                  />
                                  <label htmlFor="test1">Verify</label>
                                  <div class="error text-danger">
                                    {errors.timeVerification}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>

                      {/* Banking details */}
                      {details.bankingDetails ? (
                        <Col lg={6}>
                          <div className="details-wrap">
                            <Row noGutters>
                              <Col md={12} lg={12} className="">
                                <h5 className="px-4">Banking Details</h5>
                                <hr />
                              </Col>
                            </Row>
                            <div className="row px-4">
                              <div className="col-sm-6 mb-4">
                                <h5 className="font-weight-normal color-grey fs18">
                                  Full Name
                                </h5>
                                <h5 className="fs18 font-weight-normal">
                                  {details.bankingDetails.fullName || ""}
                                </h5>
                              </div>
                              <div className="col-sm-6 mb-4">
                                <h5 className="font-weight-normal color-grey fs18">
                                  Bank
                                </h5>
                                <h5 className="fs18 font-weight-normal">
                                  {details.bankingDetails.bankName || ""}{" "}
                                  {details.bankingDetails.bankLocation || ""}
                                </h5>
                              </div>
                              <div className="col-sm-6 mb-4">
                                <h5 className="font-weight-normal color-grey fs18">
                                  Account Number
                                </h5>
                                <h5 className="fs18 font-weight-normal">
                                  {details.bankingDetails.accountNumber || ""}
                                </h5>
                              </div>
                              <div className="col-sm-6 mb-4">
                                <h5 className="font-weight-normal color-grey fs18">
                                  ABA Number
                                </h5>
                                <h5 className="fs18 font-weight-normal">
                                  {details.bankingDetails.abaNumber || ""}
                                </h5>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                <hr />
                                {details.active ? (
                                  <div className="deliva-radio text-center">
                                    <input
                                      type="checkbox"
                                      id="test2"
                                      name="bankVerification"
                                      checked
                                    />
                                    <label htmlFor="test2">Verified</label>
                                  </div>
                                ) : (
                                  <div className="deliva-radio text-center">
                                    <input
                                      type="checkbox"
                                      id="test2"
                                      name="bankVerification"
                                      value={this.state.bankVerification}
                                      onChange={this.onChange}
                                    />
                                    <label htmlFor="test2">Verify</label>
                                    <div class="error text-danger">
                                      {errors.bankVerification}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                    {!details.active ? (
                      <Row>
                        <Col md={12} lg={12}>
                          <div className="text-center mt-5">
                            <button
                              className="btnDefault btnOutline mr-3"
                              onClick={this.handleReject.bind(
                                this,
                                details.userId
                              )}
                            >
                              Reject
                            </button>
                            <button
                              className="approve-btn btnDefault btnPrimary"
                              onClick={this.approveDA.bind(
                                this,
                                details.userId,
                                details.name
                              )}
                            >
                              Approve
                            </button>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Modal
          show={this.state.showRejectModal}
          onHide={this.handleReject.bind(this, "")}
          centered
          className="deliva-popup"
        >
          <Modal.Body>
            <div className="modal-content-wrap ">
              <div className="text-center">
                <Image
                  src={RejectIcon}
                  className="img-fluid reject-img pointer"
                  onClick={this.handleReject.bind(this, "")}
                />
                <h4 className="color-red  mt-3 fs-22">
                  Are you sure to Reject this request ?
                </h4>
                <p className="color-head">
                  Please add/choose the reason of rejection{" "}
                </p>
              </div>

              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="color-head">
                  Choose the Reason
                </Form.Label>
                <Form.Control
                  as="select"
                  name="rejectionReason"
                  onChange={this.onChange}
                  value={this.state.rejectionReason}
                  className="py-0"
                >
                  <option value="Other">Other</option>
                  <option value="Invalid Passport Details">
                    Invalid Passport Details
                  </option>
                  <option value="Invalid Banking Details">
                    Invalid Banking Details
                  </option>
                  <option value="Invalid ID Proof">Invalid ID Proof</option>
                  <option value="Criminal Background">
                    Criminal Background
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="color-head">
                  Rejection details
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="description"
                  onChange={this.onChange}
                  maxlength="100"
                />
                <span className="min-char">Min 100 chars</span>
              </Form.Group>
              <div className="text-center">
                <button
                  className="cancel-btn min-w-80 mr-4"
                  onClick={this.handleReject.bind(this, "")}
                >
                  No
                </button>
                <button
                  className="b1-btn min-w-80"
                  onClick={this.submitReject.bind(this)}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.successModal}
          onHide={() => this.approvalSuccessModal(this)}
          centered
        >
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={approved} className="img-fluid user-block-img" />
              <h4 className="lightGreenTex mt-3">Approved</h4>
              <p className="color-grey">
                {this.state.daName} #{this.state.userId} detail are approved
              </p>

              <button
                className="b1-btn min-w-80"
                onClick={() => this.approvalSuccessModal(this)}
              >
                Back
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ProcessingAgentDetails;
