import React, { Component } from 'react'
import { Row, Col, FormControl, Form } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import moment from 'moment'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import delivaApiService from '../../../common/services/apiService'
import { getLocalStorage, range } from '../../../common/helpers/Utils'
import { NavLink } from 'react-router-dom'
import Loader from '../../common/Loader'
import CONSTANTS from '../../../common/helpers/Constants'

class OrderList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sortBy: '',
      sortOrder: '',
      search: '',
      orderList: [],
      roleId: '',
      totalCount: 0,
      showLoader: false,
      listFor: 'pending',
      // selectedOption: "pending"
    }
  }

  componentDidMount() {
    this.setState({
      listFor: this.props.location?.state?.selectedValue
        ? this.props.location?.state?.selectedValue
        : 'pending',
    })
    if (getLocalStorage('adminInfo')) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    } else {
      this.props.history.push('/login')
    }
  }
  update() {
    this.getList(1, this.state.records, this.state.sortBy, this.state.sortOrder)
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(1, e.target.value, this.state.sortBy, this.state.sortOrder)
    } else {
      this.setState({
        records: '',
      })
    }
  }

  onChangeSort(val) {
    this.setState({ sortOrder: this.state.sortOrder === 'desc' ? '' : 'desc' })
    if (this.state.sortOrder === 'desc') {
      this.getList(this.state.pageno, this.state.records, val, 'asc')
    } else {
      this.getList(this.state.pageno, this.state.records, val, 'desc')
    }
  }

  getList(pageno, records, sortBy, sortOrder) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sortBy: sortBy,
        sortOrder: sortOrder,
        orderList: [],
      },
      () => {
        this.setLoader(true)
      }
    )

    delivaApiService('getListOfCompletedOrdersToBePaid', {
      roleId: this.props.match.params?.id,
      pageSize: records,
      pageNo: pageno,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchKey: this.state.search,
      listFor: this.props.location?.state?.selectedValue
        ? this.props.location?.state?.selectedValue
        : this.state.listFor,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.totalRecords)
          this.setState(
            {
              orderList: response.data.resourceData,
              totalCount: response.data.totalRecords,
            },
            () => {
              this.setLoader(false)
            }
          )
        } else {
          this.setLoader(false)
        }
      })
      .catch(this.setLoader(false))
  }
  getPager(total) {
    this.setState(
      {
        records:
          this.state.records == 0
            ? CONSTANTS.SHOWRECORDS.NOOFRECORDS
            : this.state.records,
      },
      () => {
        let startPage = this.state.startPage
        let endPage = this.state.endPage
        let totalPage = Math.ceil(total / this.state.records)
        let pageno = this.state.pageno

        if (totalPage <= 5) {
          startPage = 1
          endPage = totalPage
        } else {
          if (pageno <= 3) {
            startPage = 1
            endPage = 5
          } else if (pageno + 1 >= totalPage) {
            startPage = totalPage - 4
            endPage = totalPage
          } else {
            startPage = pageno - 2
            endPage = pageno + 2
          }
        }
        let startIndex = (pageno - 1) * this.state.records
        let endIndex = Math.min(
          startIndex + this.state.records - 1,
          totalPage - 1
        )

        // create an array of pages to ng-repeat in the pager control
        let pageArray
        if (startPage === endPage) {
          pageArray = [1]
        } else {
          pageArray = range(startPage, endPage)
        }
        this.setState({
          // records: this.state.records,
          totalPage: totalPage,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pageArray: pageArray,
        })
      }
    )
  }

  handleChangeInput = (e) => {
    this.setState({ search: e.target.value }, () => {
      if (this.state.search === '' || this.state.search.trim() === '') {
        this.getList(
          1,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        )
      }
    })
  }

  onChangePage(page) {
    if (page === this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }

  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  handleSearch() {
    if (this.state.search.trim() !== '') {
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }
  change = (e) => {
    let data = { order: e.target.value }
    this.setState({ listFor: e.target.value }, () => {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    })
  }

  render() {
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />
        <div className="mainContainer order-list-new-table CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3 align-items-center">
                    <Col md={4} lg={4} className="">
                      <h3 className="font-weight-normal mb-2 mb-lg-0 tertiary-color fs-24">
                        Order List
                        <span className="tertiary-color">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                    </Col>

                    <Col md={8} lg={8} className="text-right">
                      <div
                        className="d-flex justify-content-end"
                        style={{ gap: "15px" }}
                      >
                        <div className="form-group mb-lg-0">
                          <FormControl
                            type="text"
                            placeholder="Search by Request ID, Location"
                            className="searchTxt mb-3 mb-lg-0"
                            onChange={(e) => this.handleChangeInput(e)}
                            onKeyDown={this.handleEnter.bind(this)}
                          />
                          <span
                            className="icon-search"
                            onClick={() => this.handleSearch()}
                          ></span>
                        </div>
                        <Form.Group
                          controlId="formBasicSelect"
                          className="mb-0"
                        >
                          <Form.Control
                            as="select"
                            onChange={this.change}
                            value={this.state.listFor}
                            className="w-100"
                          >
                            <option value="pending">Transfer Pending</option>
                            <option value="all">Show All</option>
                            <option value="domestic">Domestic Order</option>
                            <option disabled={true} value="international">
                              International Order
                            </option>
                            <option value="delivered">Delivered</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row noGutters className="justify-content-end custom-column-ui">
                    <Col md={6} lg={2} className="text-right">
                      <Form.Group controlId="formBasicSelect">
                        <Form.Control
                          as="select"
                          onChange={this.change}
                          value={this.state.listFor}
                        >
                          <option value="all">Show All</option>
                          <option value="pending">Transfer Pending</option>
                          <option value="domestic">Domestic Order</option>
                          <option value="international">
                            International Order
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <div></div>
                  <div className="row">
                    <div className="col-sm-12 order-boxlist">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Request ID
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("requestId")}
                              ></a>
                            </th>
                            <th>
                              Destination Location
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() =>
                                  this.onChangeSort("destinationLocation")
                                }
                              ></a>
                            </th>
                            <th>
                              Source Location
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() =>
                                  this.onChangeSort("originatingLocation")
                                }
                              ></a>
                            </th>
                            <th>
                              Payment Status
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() =>
                                  this.onChangeSort("paymentTrasfered")
                                }
                              ></a>
                            </th>
                            <th>
                              Order Type
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("requestType")}
                              ></a>
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="ttl-order-table">
                          {!this.state.orderList.length ? (
                            <tr>
                              <td colSpan={7} style={{ textAlign: "center" }}>
                                No Records Available
                              </td>
                            </tr>
                          ) : (
                            this.state.orderList.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {item.requestId ? item.requestId : "-"}
                                  </td>
                                  <td>
                                    <div className="">
                                      {item.destinationLocation}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="">
                                      {item.originatingLocation}
                                    </div>
                                  </td>
                                  <td>
                                    {item.paymentTrasfered
                                      ? <span className='text-green'>{CONSTANTS.PAYMENTSTATUS.SUCCESS}</span>
                                      : <span style={{ color: '#F47249' }}>{CONSTANTS.PAYMENTSTATUS.PENDING}</span>}
                                  </td>
                                  <td>
                                    {item.requestType ? item.requestType : "-"}
                                  </td>
                                  <td className="views-icos">
                                    <NavLink
                                      to={{
                                        pathname: `/billing/orderDetails/${item.requestId}`,
                                        state: { listFor: this.state.listFor }, // Pass the state here
                                      }}
                                    >
                                      <span className="icon-eye"></span> View
                                    </NavLink>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </Table>
                      {this.state.orderList.length ? (
                        <nav aria-label="Page navigation">
                          <ul className="pagination pg-blue deliva-pagination justify-content-end pr-180p">
                            <li className="page-item">
                              <button
                                className="page-link rotate-180 control-btn"
                                aria-label="Previous"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno - 1)
                                }
                                disabled={
                                  this.state.pageno === 1 ||
                                  this.state.totalPage === 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  //className="prevNext"
                                  className={`sr-only ${
                                    this.state.pageno === 1 ||
                                    this.state.totalPage === 0
                                      ? ""
                                      : "active"
                                  }`}
                                >
                                  Previous
                                </span>
                              </button>
                            </li>
                            {this.state.totalPage > 0 &&
                              this.state.pageArray.map((page, ind) => {
                                return (
                                  <li key={ind} className="page-item">
                                    <a
                                      className={`page-link ${
                                        this.state.pageno === page
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => this.onChangePage(page)}
                                    >
                                      {page}
                                    </a>
                                  </li>
                                );
                              })}

                            <li className="page-item">
                              <button
                                className="page-link control-btn"
                                aria-label="Next"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno + 1)
                                }
                                disabled={
                                  this.state.pageno === this.state.totalPage ||
                                  this.state.totalPage === 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  className={`sr-only ${
                                    this.state.pageno ===
                                      this.state.totalPage ||
                                    this.state.totalPage === 0
                                      ? ""
                                      : "active"
                                  }`}
                                >
                                  Next
                                </span>
                              </button>
                            </li>
                            <li className="page-value">
                              Enter Per Page{" "}
                              <input
                                type="text"
                                placeholder={CONSTANTS.SHOWRECORDS.NOOFRECORDS}
                                name="records"
                                value={this.state.records}
                                onChange={(e) => this.onChangeItemPerPage(e)}
                              />
                            </li>
                          </ul>
                        </nav>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}
export default OrderList
