import React, { Component } from "react";
import uniq from "lodash/uniq";
import { Row, Col, Image } from "react-bootstrap";
import logo from "../../../assets/images/logo-sidebar.svg";
import ProfileImg from "../../../assets/images/user.png";
import Dropdown from "react-bootstrap/Dropdown";
import delivaApiService from "../../../common/services/apiService";
import {
  getLocalStorage,
  clearLocalStorage,
  timeSince,
  removedUnderscore,
} from "../../../common/helpers/Utils";
import CONSTANTS from "../../../common/helpers/Constants";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: [],
      notificationLength: 0,
      showLoader: false,
      pageNumber: 1,
      pageSize: CONSTANTS.NOTIFICATIONACTIONS.PAGINATIONSIZE,
      unReadNotificationCount: 0,
    };
  }
  componentDidMount() {
    this.getNotifications(getLocalStorage("id"));
  }

  handleLogout() {
    clearLocalStorage();
    this.props.history.push("/login");
  }

  getNotifications = (id) => {
    let data = {
      userId: id,
      record: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    delivaApiService("getAllNotification", data)
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          console.log(response.data.resourceData.notificationHeaderResponse);
          this.setState({
            notification: uniq([
              ...this.state.notification,
              ...response.data.resourceData.notificationHeaderResponse[0].notificationResponse,
            ]),
            notificationLength:
              response.data.noOfRecords || this.state.notification?.length,
            unReadNotificationCount:
              response.data.resourceData.unReadNotificationCount,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleRedirectDashboard() {
    this.props.history.push("/dashboard");
  }

  handlereadNotification = (e, ele, id) => {
    let data = { userId: getLocalStorage("id") };

    //this.props.history.push("/notifications");

    delivaApiService("readNotification", data);
  };
  handlePagination = (e) => {
    this.setState(
      {
        pageNumber: this.state.pageNumber + 1,
        unReadNotificationCount:0
      },
      () => {
        this.getNotifications(getLocalStorage("id"));
        //this.props.history.push("/notifications");
      }
    );
  };
  getKeyByValue = (object, value) => {
    return removedUnderscore(
      Object.keys(object).find((key) => object[key] === value)
    );
  };
  render() {
    console.log(this.state.notification);
    return (
      <Row noGutters className="topbarWrapper">
        <Col md={3} lg={2} className="logo d-flex align-items-center">
          <span
            className="pointer"
            onClick={() => this.props.history.push("/dashboard")}
          >
            <Image
              src={logo}
              alt="Deliva Logo"
              title="Welcome to Deliva"
              className="w-auto pl-0"
            />
          </span>
        </Col>
        <Col md={9} lg={10} className="boxTyp3 bdr-B box-shadow">
          <div className="topbar">
            <Row noGutters className=" align-items-center">
              <Col md={6} lg={4} className="">
                {/* <FormControl
                  type="text"
                  placeholder="Search here"
                  className="searchTxt"
                />
                <span className="icon-search"></span> */}
              </Col>
              <Col md={6} lg={8} className="text-right">
                <div className="line-ht profile-notifications">
                  <Dropdown className="notification-sections">
                    <Dropdown.Toggle id="notification-dropdown">
                      <span className="bellOuterBox">
                        <span className="fs34 icon-notification-bell dark-grey"></span>

                        {this.state.unReadNotificationCount > 0 ? (
                          <span className="notificationCount">
                            {this.state.unReadNotificationCount}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </Dropdown.Toggle>
                    {this.state.notification?.length > 0 ? (
                      <Dropdown.Menu>
                        <Dropdown.Item className="dropdown-head">
                          Notifications
                        </Dropdown.Item>
                        {/* CONSTANTS.ROLES[ele.notifictionAction] */}
                        <ul className="notification-list">
                          {this.state.notification?.map((ele, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                className="active"
                                onClick={(e) =>
                                  this.handlereadNotification(
                                    e,
                                    ele,
                                    ele.notificationId
                                  )
                                }
                              >
                                <span class="time-label fs15 ">
                                  {timeSince(ele.dateTime)}
                                </span>
                                <span
                                  className={`dot-cicles ${
                                    ele.read ? "dot-grays" : "dot-yellow"
                                  }`}
                                ></span>
                                <h6>
                                  {this.getKeyByValue(
                                    CONSTANTS.ROLES,
                                    ele.roleId
                                  )}
                                </h6>
                                <h6 className="color-grey">
                                  {ele.notification}
                                </h6>
                              </Dropdown.Item>
                            );
                          })}
                        </ul>
                        {this.state.notification.length >= 1 ? (
                          <li
                            className="dropdown-footer pointer text-uppercase py-3 text-green"
                            onClick={(e) => this.handlePagination(e)}
                          >
                            see more
                          </li>
                        ) : null}
                      </Dropdown.Menu>
                    ) : null}
                  </Dropdown>
                  <a
                    onClick={() => this.props.history.push("/settings/")}
                    className="fs34 icon-setting-gear pointer"
                    title="Settings"
                  ></a>
                  <a
                    onClick={() => {
                      this.handleLogout();
                    }}
                    title="Log Out"
                    className="fs30 icon-logout-new pointer"
                  ></a>

                  <Dropdown className="headerDropdown2 d-none">
                    <Dropdown.Toggle variant="default" className="p-0">
                      <Image src={ProfileImg} alt="life Savor" height="55px" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.handleLogout();
                        }}
                      >
                        <span className="icon-logout"></span> Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Header;
