import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import matchSorter from "match-sorter";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col } from "react-bootstrap";
import delivaApiService from "../../../common/services/apiService";
import { getLocalStorage } from "../../../common/helpers/Utils";
import Pagination from "../DeliveryAgent/perfectpage";
import CONSTANTS from '../../../common/helpers/Constants';

class TotalProcessingOrders extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      searchInput: "",
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: "default", value: 5 }],
      pageSize: 5,
      agentName: null
    };
    this.filterAll = this.filterAll.bind(this);
  }
  componentDidMount() {
    this.setState(
      {
        agentName: this.props.location.state.businessName
          ? this.props.location.state.businessName
          : null,
      });
    this.getDAOrders();
  }
  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }
  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      // NOTE: this removes any FILTER ALL filter
      const filterAll = "";
      this.setState({
        filtered: filtered.filter((item) => item.id != "all"),
        filterAll,
      });
    } else this.setState({ filtered });
  }
  onRezise(page) {
    console.log(page);
    // if (filtered.length > 1 && this.state.filterAll.length) {
    //   // NOTE: this removes any FILTER ALL filter
    //   const filterAll = "";
    //   this.setState({
    //     filtered: filtered.filter((item) => item.id != "all"),
    //     filterAll,
    //   });
    // } else this.setState({ filtered });
  }
  filterAll(e) {
    console.log(e);
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: "all", value: filterAll }];
    console.log(filtered);
    this.setState({ filterAll, filtered });
  }

  handleRouteChange(val) {
    this.props.history.push(val);
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.details.filter((value) => {
      return (
        value.packageName.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.destinationLocation.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.pickUpLocation.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  getDAOrders() {
    // this.setLoader(true);
    delivaApiService("getTotalOrderProcessedPA", { id: getLocalStorage("PAID") })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          console.log("response.data.", response.data.resourceData);
          this.setState(
            {
              details: response.data.resourceData.totalProcessedOrderList,
              totalTrips: response.data.resourceData.totalProcessedOrderCount,
            },
            () => {
              this.setState({
                data: this.state.details.map((item) => {
                  return item;
                }),
              });
            }
          );
        } else {
          //   this.setLoader(false);
        }
      })
      .catch((err) => {
        // this.setLoader(false);
      });
  }
  hello(pageSize) {
    this.setState({
      pageSize,
    });
  }

  orderStatus = (status) => {
    if (status === CONSTANTS.ORDER_STATUS.TRANSIT) {
      return 'In transit';
    } else if (status === CONSTANTS.ORDER_STATUS.ACCEPTED) {
      return 'Accepted bid';
    } else if (status === CONSTANTS.ORDER_STATUS.DELIVERED) {
      return 'Delivered';
    } else if (status == CONSTANTS.ORDER_STATUS.CANCELLED) {
      return 'Cancelled';
    } else if (status === CONSTANTS.ORDER_STATUS.REJECTED) {
      return 'Pickup rejected';
    }
  };

  render() {
    const columns = [
      {
        Header: 'Order ID',
        id: 'orderId',
        accessor: (d) => d.orderId,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['orderId'] }),
        filterAll: true,
      },

      {
        Header: 'Order Name',
        id: 'packageName',
        accessor: (d) => d.packageName,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!      
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['packageName'] }),
        filterAll: true,
      },

      {
        Header: 'Pickup Location',
        id: 'pickUpLocation',
        accessor: (d) => d.pickUpLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!       
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['pickUpLocation'] }),
        filterAll: true,
      },
      {
        Header: 'Destination Location',
        id: 'destinationLocation',
        accessor: (d) => d.destinationLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!       
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['destinationLocation'] }),
        filterAll: true,
      },

      {
        Header: 'Status',
        id: 'status',
        accessor: (d) => this.orderStatus(d.status),
        Cell: (props) => (
          <span className={props.value === "Delivered" ? "text-green" : props.value === "In transit" ? "text-orange" : props.value==="Cancelled" ? "text-red" : "text-bluish"}>
            {props.value}
          </span>
        ), // Custom cell components!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['status'] }),
        filterAll: true,
      },

      {
        Header: "All",
        id: "all",
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => { },
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {
          console.log(filter);
          console.log(rows);
          const result = matchSorter(rows, filter.value, {
            keys: [
              "originatingLocation",
              "destinationLocation",
              "status"
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          console.log("row[0]:", result[0]);
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24">
                        Total Orders Processed{" "}
                        <span className="color-grey">
                          ({this.state.totalTrips})
                        </span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht color-grey">
                        <span onClick={() => this.handleRouteChange('/processingagentlist')} className="pointer"> Processing agents </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        <span onClick={() => this.props.history.goBack()} className="pointer">
                          {this.state.agentName}
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        Total orders
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.searchInput || ""}
                        onChange={this.handleChange}
                        label="Search"
                        type="text"
                        placeholder="Search by Order name, Location"
                        className="searchTxt w-100"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-wrap">
                        <ReactTable
                          data={this.state.data}
                          columns={this.state.pageSize ? columns : []}
                          //   pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                          defaultPageSize={this.state.defaultPageSize}
                          pageSize={this.state.pageSize}
                          showPageSizeOptions={true}
                          filtered={this.state.filtered}
                          //   resized={this.state.resized}
                          ref={(r) => (this.reactTable = r)}
                          onFilteredChange={this.onFilteredChange.bind(this)}
                          onPageSizeChange={(pageSize, pageIndex) => {
                            console.log(pageSize, pageIndex);
                          }}
                          //   filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.age]) === filter.value
                          }
                          PaginationComponent={Pagination}
                        />
                        <span className="page-value">
                          Enter Per Page{" "}
                          <input
                            type="text"
                            placeholder="07"
                            name="defaultPageSize"
                            value={this.state.pageSize}
                            onChange={(e) => this.hello(e.target.value)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default TotalProcessingOrders;
