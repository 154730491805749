import React, { Component } from "react";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col,Image,FormControl } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import AdminProfilePic from "../../../assets/images/AdminProfilePic.svg";
import IdProof1 from "../../../assets/images/IdProof1.svg";
import IdProof2 from "../../../assets/images/IdProof2.svg";



class EditProfile extends Component {
    render() {
        
      return (
        <div className="page__wrapper h-100">
            <Header {...this.props} />

            <div className="mainContainer CustomerTable">
                <Row noGutters className="h-100">
                    <Sidebar {...this.props} />
                    <Col md={9} lg={10} className="boxTyp3">
                        <div className="container-fluid">
                            <div className="content-wrapper">
                                <Row>
                                    <Col md={10} lg={6} className="offset-md-1 offset-lg-3">
                                        <div className="Admin-form-wrap">
                                            <div className="admin-form-inner-wrap">
                                                
                                                <div className="text-center p-4 position-relative">
                                                    <img src={AdminProfilePic} className="profile-pic"/>
                                                    <a href="" className="edit-btn1"><span className="icon-edit-ico"></span></a>
                                                </div>
                                                
                                                    <div className="p-5">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <Form>
                                                                    <Form.Group controlId="formBasicEmail">
                                                                        <Form.Control
                                                                        type="text"
                                                                        placeholder="Jhon"
                                                                        name="name"
                                                                        className="login-input mb-4"
                                                                        />
                                                                        
                                                                    </Form.Group>
                                                                </Form>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <Form>
                                                                    <Form.Group controlId="formBasicEmail">
                                                                        <Form.Control
                                                                        type="text"
                                                                        placeholder="Doe"
                                                                        name="lastname"
                                                                        className="login-input mb-4"
                                                                        />
                                                                        
                                                                    </Form.Group>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <Form>
                                                                    <Form.Group controlId="formBasicEmail">
                                                                        <Form.Control
                                                                        type="email"
                                                                        placeholder="JohnDoe@gmail.com "
                                                                        name="username"
                                                                        className="login-input mb-4"
                                                                        />
                                                                        
                                                                    </Form.Group>
                                                                </Form>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <Form>
                                                                    <Form.Group controlId="formBasicEmail">
                                                                        <Form.Control
                                                                        type="password"
                                                                        placeholder="************* "
                                                                        name="password"
                                                                        className="login-input mb-4"
                                                                        />
                                                                        
                                                                    </Form.Group>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                            <Button
                                                                variant="primary"
                                                                type="button"
                                                                className="deliva-btn"
                                                            >
                                                                Save
                                                            </Button>
                                                            </div>
                                                            <div className="col-sm-6 text-right">
                                                            <a
                                                            className="auth-link pointer text-grey ln-ht"
                                                            role="button"
                                                            >
                                                            back to <span className="text-warning">Home</span>
                                                            </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                
                                                
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Footer {...this.props} />
                    </Col>
                </Row>
            </div>
        </div>
        
      )
    }
  }
  export default EditProfile;