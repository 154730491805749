import React, { Component } from "react";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col,Image,FormControl } from 'react-bootstrap';

import AdminProfilePic from "../../../assets/images/AdminProfilePic.svg";
import IdProof1 from "../../../assets/images/IdProof1.svg";
import IdProof2 from "../../../assets/images/IdProof2.svg";



class AdminProfile extends Component {
    render() {
        
      return (
        <div className="page__wrapper h-100">
            <Header {...this.props} />

            <div className="mainContainer CustomerTable">
                <Row noGutters className="h-100">
                    <Sidebar {...this.props} />
                    <Col md={9} lg={10} className="boxTyp3">
                        <div className="container-fluid">
                            <div className="content-wrapper">
                                <Row>
                                    <Col md={6} lg={6} className="offset-md-3 offset-lg-3">
                                        <div className="Admin-form-wrap">
                                            <div className="admin-form-inner-wrap">
                                                <a href="" className="edit-btn"><span className="icon-edit-ico"></span></a>
                                                <div className="text-center p-4">
                                                    <img src={AdminProfilePic} className="profile-pic"/>
                                                    <h3 className="admin-name my-3">Bare Jackson</h3>
                                                </div>
                                                <div className="account-detail-wrap ">
                                                    <div className="w-75 m-auto">
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <h4 className="color-grey fs22">Email:</h4>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <p className="fs22">barejackson@gmail.com</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <h4 className="color-grey fs22">Password:</h4>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <p className="fs22">***********</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Footer {...this.props} />
                    </Col>
                </Row>
            </div>
        </div>
        
      )
    }
  }
  export default AdminProfile;