import React, { Component } from 'react'
import { Row, Col, Button, Image } from 'react-bootstrap'
import forgotImage from '../../../assets/images/auth/forgot-password.svg'
import Form from 'react-bootstrap/Form'
import validateInput from '../../../common/validations/validationForgotPassword'
import lifeSavorApiService from '../../../common/services/apiService'
import Loader from '../../common/Loader'
import delivaApiService from '../../../common/services/apiService'
import CONSTANTS from '../../../common/helpers/Constants'
import { showErrorToast } from '../../../common/helpers/Utils'

class AdminForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: this.props?.location?.state?.email
        ? this.props?.location?.state?.email
        : '',
      errors: {},
      showLoader: false,
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  isValid() {
    const { errors, isValid } = validateInput({ email: this.state.email })
    if (!isValid) {
      this.setState({ errors })
    }
    return isValid
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    this.setState({
      showLoader: true,
    })
    // this.props.history.push("/admin/login");

    if (this.isValid()) {
      delivaApiService('forgotPassword', {
        email: this.state.email,
        role: 1,
      }).then((result) => {
        console.log('result', result)
        if (result?.data?.status == '404') {
          showErrorToast(result.data.message)
          this.setState({
            showLoader: false,
          })
        } else if (result && result.data) {
          this.props.history.push('/login')
          this.setState({
            showLoader: false,
          })
        } else {
          this.setState({
            showLoader: false,
          })
        }
      })
    } else {
      this.setState({
        showLoader: false,
      })
    }
  }
  handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault()
    }
  }
  handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')
    if (/\s/.test(pastedData)) {
      event.preventDefault()
      const sanitizedData = pastedData.replace(/\s/g, '')
      document.execCommand('insertText', false, sanitizedData)
    }
  }

  render() {
    const { email, errors } = this.state
    console.log(this.props, 'pppppppppppppppppppppppp')

    return (
      <div className="page__wrapper">
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth login">
              <div className="row w-100">
                <div className="col-lg-8 mx-auto">
                  <div className="deliva-form boxShadow1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex align-items-center justify-content-center py-3 h-100">
                          <div className="login-wrap forgots">
                            <h4 className="text-info font-weight-normal">
                              Forgot Password ?
                            </h4>
                            <p className="text-grey">
                              Enter your{' '}
                              <a className="text-warning">email address</a> that
                              you used to register. We'll send you an email with
                              your username and a link to reset your password.
                            </p>
                            <Form>
                              <Form.Group className="mb-3 pb-3">
                                <Form.Control
                                  type="email"
                                  placeholder="Enter email ID"
                                  className="login-input"
                                  fullWidth
                                  error={errors.email ? true : false}
                                  id="outlined-email"
                                  name="email"
                                  variant="outlined"
                                  value={email}
                                  onChange={this.handleChange}
                                  onKeyPress={this.handleEnter}
                                  onKeyDown={this.handleKeyDown}
                                  onPaste={this.handlePaste}
                                  autoComplete="off"
                                  maxLength={CONSTANTS.MAXLENGTH.EMAIL}
                                  onDragOver={(e) => {
                                    e.preventDefault()
                                  }}
                                />
                                <div className="error alignLeft position-absolute mt-4 pt-1">
                                  {errors.email}
                                </div>
                              </Form.Group>
                              <Button
                                variant="primary"
                                type="button"
                                className="deliva-btn mb-5"
                                onClick={() => this.handleSubmit()}
                              >
                                Submit
                              </Button>
                            </Form>
                            <a
                              className="auth-link pointer text-grey"
                              role="button"
                              onClick={() => this.props.history.push('/')}
                            >
                              back to <span className="text-blue">Home</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 p-5">
                        <Image src={forgotImage} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    )
  }
}
export default AdminForgotPassword
