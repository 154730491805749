import React, { Component } from 'react'
import { Row, Col, Image } from 'react-bootstrap'

import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import StoreArrow from '../../../assets/images/StoreArrow.svg'
import delivaApiService from '../../../common/services/apiService'
import { setLocalStorage, getLocalStorage } from '../../../common/helpers/Utils'
import halfStar from '../../../assets/images/halfstar.svg'
import fullStar from '../../../assets/images/fullstar.svg'
import emptyStar from '../../../assets/images/emptystar.svg'

class StoreProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sort: '',
      keyword: '',
      search: '',
      pAList: [],
      details: [],
      totalOrders: [],
      packageCOuntForPA: [],
      totalTrips: 0,
    }
  }

  componentDidMount() {
    this.setState(
      {
        id: this.props.location.state.id
          ? this.props.location.state.id
          : getLocalStorage('PAID'),
      },
      () => {
        if (this.props.location.state.id) {
          setLocalStorage('PAID', this.props.location.state.id)
        }
        this.getProcessingAgentDetail()
        this.getPackageCountForPA()
        this.getTotalProceessedCount()
      }
    )
  }

  getTotalProceessedCount() {
    delivaApiService('getTotalOrderProcessedPA', {
      id: getLocalStorage('PAID'),
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState({
            totalTrips: response.data.resourceData.totalProcessedOrderCount,
          })
        }
      })
      .catch((err) => {
        // this.setLoader(false);
      })
  }

  getProcessingAgentDetail() {
    this.setLoader(true)
    delivaApiService('getProcessingAgentDetail', { id: this.state.id })
      .then((response) => {
        if (response && response.data) {
          this.setState({ details: response.data.resourceData })
        } else {
          this.setLoader(false)
        }
      })
      .catch((err) => {
        this.setLoader(false)
      })
  }

  getPackageCountForPA() {
    this.setLoader(true)
    delivaApiService('getPackageCountForPA', { id: this.state.id })
      .then((response) => {
        if (response && response.data) {
          this.setState({ packageCOuntForPA: response.data.resourceData })
        } else {
          this.setLoader(false)
        }
      })
      .catch((err) => {
        this.setLoader(false)
      })
  }

  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  operatingTime = (week) => {
    const { details } = this.state
    var t_1 = 'Closed'

    if (details?.operationalHours && details?.operationalHours.days) {
      const dayInfo = details.operationalHours.days.find(
        (item) => item.dayName === week
      )

      if (dayInfo) {
        if (dayInfo.open) {
          t_1 = `${dayInfo.startTime} - ${dayInfo.endTime}`
        }
      }
    }

    return t_1
  }

  render() {
    const { packageCOuntForPA, details } = this.state
    let drawRating = [1, 2, 3, 4, 5]
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 fs22 line-ht tertiary-color">
                        {details?.businessName ? details?.businessName : ""}{" "}
                      </h3>
                      <h5 className="font-weight-normal mb-0 fs18 line-ht color-grey">
                        <span
                          onClick={() =>
                            this.props.history.push("/processingagentlist")
                          }
                          className="pointer"
                        >
                          {" "}
                          Processing Centres{" "}
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>{" "}
                        {details?.businessName ? details?.businessName : ""}{" "}
                      </h5>
                    </Col>
                  </Row>
                  <div className="cust-details-wrap mb-4 storeProfileBg border-0">
                    <Row noGutters>
                      <Col md={4} lg={4}>
                        <div className="row">
                          <div className="col-sm-12 ">
                            <div className="d-flex h-100 align-items-center">
                              <div className="pr-wrap">
                                <h4 className="fs22">
                                  {details?.businessName
                                    ? details?.businessName
                                    : ""}
                                </h4>
                                <div className="ratings my-2">
                                  {drawRating.map((item, i) => {
                                    const ratingFloor = Math.floor(
                                      details?.rating
                                    );
                                    const hasHalfStar =
                                      details?.rating - ratingFloor >= 0.5;

                                    if (i < ratingFloor) {
                                      return (
                                        <span key={i}>
                                          <Image
                                            src={fullStar}
                                            className="img-fluid"
                                          />
                                        </span>
                                      );
                                    } else if (
                                      i === ratingFloor &&
                                      hasHalfStar
                                    ) {
                                      return (
                                        <span key={i}>
                                          <Image
                                            src={halfStar}
                                            className="img-fluid"
                                          />
                                        </span>
                                      );
                                    } else {
                                      return (
                                        <span key={i}>
                                          <Image
                                            src={emptyStar}
                                            className="img-fluid"
                                          />
                                        </span>
                                      );
                                    }
                                  })}
                                  <span>{details?.rating}</span>
                                </div>
                                <p className="color-head mb-0">
                                  Business Reg no. : #
                                  {details?.businessRegistrationNumber
                                    ? details?.businessRegistrationNumber
                                    : ""}
                                </p>
                                <p className="color-head mb-0">
                                  Business Type:{" "}
                                  {details?.businessType
                                    ? details?.businessType
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={5} lg={6}>
                        <div className="d-flex h-100 align-items-center pl-3">
                          <ul className="pl-2 detail-list mb-0 color-head">
                            <li>
                              <span className="icon-phone"></span>+
                              {details?.countryCode ? details?.countryCode : ""}{" "}
                              {details?.mobile ? details?.mobile : ""}
                            </li>
                            <li>
                              <span className="icon-email"></span>
                              {details?.email ? details?.email : ""}
                            </li>
                            <li className="d-flex">
                              <span className="icon-pin map-ico-user"></span>
                              {details?.bussinessAddress &&
                              details?.bussinessAddress?.aptNumber
                                ? details?.bussinessAddress?.aptNumber + " "
                                : ""}

                              {details?.bussinessAddress &&
                              details?.bussinessAddress?.address
                                ? details?.bussinessAddress?.address + ", "
                                : ""}

                              {details?.bussinessAddress &&
                              details?.bussinessAddress?.city
                                ? details?.bussinessAddress?.city + ", "
                                : ""}

                              {details?.bussinessAddress &&
                              details?.bussinessAddress?.state
                                ? details?.bussinessAddress?.state + ", "
                                : ""}

                              {details?.bussinessAddress &&
                              details?.bussinessAddress?.country
                                ? details?.bussinessAddress?.country
                                : ""}
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col
                        md={3}
                        lg={2}
                        className="d-flex justify-content-end align-items-start"
                      >
                        <div className="set-img-top position-relative">
                          <Image
                            src={StoreArrow}
                            className="img-fluid store-arrow-img"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col md={6} lg={6}>
                      <div className="details-wrap mb-4">
                        <Row noGutters>
                          <Col md={12} lg={12} className="">
                            <h5 className="px-4">Operational Hours</h5>
                            <hr />
                          </Col>
                        </Row>
                        <div className="row px-4">
                          <div className="col-sm-6">
                            <ul className="hrs-list">
                              <li key={"Monday"}>
                                <div className="row no-gutters">
                                  <div className="col-sm-5">
                                    <span className="color-grey">Monday</span>
                                  </div>
                                  <div className="col-sm-7 text-right fw500">
                                    {this.operatingTime("Monday")}
                                  </div>
                                </div>
                              </li>
                              <li key={"Tuesday"}>
                                <div className="row no-gutters">
                                  <div className="col-sm-5">
                                    <span className="color-grey">Tuesday</span>
                                  </div>
                                  <div className="col-sm-7 text-right fw500">
                                    {this.operatingTime("Tuesday")}
                                  </div>
                                </div>
                              </li>
                              <li key={"Wednesday"}>
                                <div className="row no-gutters">
                                  <div className="col-sm-5">
                                    <span className="color-grey">
                                      Wednesday
                                    </span>
                                  </div>
                                  <div className="col-sm-7 text-right fw500">
                                    {this.operatingTime("Wednesday")}
                                  </div>
                                </div>
                              </li>
                              <li key={"Thursday"}>
                                <div className="row no-gutters">
                                  <div className="col-sm-5">
                                    <span className="color-grey">Thursday</span>
                                  </div>
                                  <div className="col-sm-7 text-right fw500">
                                    {this.operatingTime("Thursday")}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-sm-6">
                            <ul className="hrs-list">
                              <li key={"Friday"}>
                                <div className="row no-gutters">
                                  <div className="col-sm-5">
                                    <span className="color-grey">Friday </span>
                                  </div>
                                  <div className="col-sm-7 text-right fw500">
                                    {this.operatingTime("Friday")}
                                  </div>
                                </div>
                              </li>
                              <li key={"Saturday"}>
                                <div className="row no-gutters">
                                  <div className="col-sm-5">
                                    <span className="color-grey">Saturday</span>
                                  </div>
                                  <div className="col-sm-7 text-right fw500">
                                    {this.operatingTime("Saturday")}
                                  </div>
                                </div>
                              </li>
                              <li key={"Sunday"}>
                                <div className="row no-gutters">
                                  <div className="col-sm-5">
                                    <span className="color-grey">Sunday</span>
                                  </div>
                                  <div className="col-sm-7 text-right fw500">
                                    {this.operatingTime("Sunday")}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} lg={6}>
                      <div className="details-wrap mb-4">
                        <Row noGutters>
                          <Col md={12} lg={12} className="">
                            <h5 className="px-4">Banking Details</h5>
                            <hr />
                          </Col>
                        </Row>
                        <div className="row px-4">
                          <div className="col-sm-6 mb-4">
                            <h5 className="font-weight-normal color-grey fs18">
                              Full Name
                            </h5>
                            <h5 className="fs18 font-weight-normal">
                              {details?.bankingDetails &&
                              details?.bankingDetails?.fullName
                                ? details?.bankingDetails?.fullName
                                : ""}
                            </h5>
                          </div>
                          <div className="col-sm-6 mb-4">
                            <h5 className="font-weight-normal color-grey fs18">
                              Bank
                            </h5>
                            <h5 className="fs18 font-weight-normal">
                              {details?.bankingDetails &&
                              details?.bankingDetails?.bankName
                                ? details?.bankingDetails?.bankName + " "
                                : " "}

                              {details?.bankingDetails &&
                              details?.bankingDetails?.bankLocation
                                ? details?.bankingDetails?.bankLocation
                                : ""}
                            </h5>
                          </div>
                          <div className="col-sm-6 mb-4">
                            <h5 className="font-weight-normal color-grey fs18">
                              Account Number
                            </h5>
                            <h5 className="fs18 font-weight-normal">
                              {details?.bankingDetails &&
                              details?.bankingDetails?.accountNumber
                                ? details?.bankingDetails?.accountNumber
                                : ""}
                            </h5>
                          </div>
                          <div className="col-sm-6">
                            <h5 className="font-weight-normal color-grey fs18">
                              ABA Number
                            </h5>
                            <h5 className="fs18 font-weight-normal">
                              {details?.bankingDetails &&
                              details?.bankingDetails?.abaNumber
                                ? details?.bankingDetails?.abaNumber
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4} lg={4}>
                      <div className="storeCol bg1">
                        <div
                          className="row justufy-content-center align-items-center m-0 pointer"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/totalprocessingorders/",
                              state: {
                                businessName: details.businessName
                                  ? details.businessName
                                  : "",
                              },
                            });
                          }}
                        >
                          <div className="col-sm-3">
                            <div className="numberWrap">
                              <p>{this.state.totalTrips}</p>
                            </div>
                          </div>
                          <div className="col-sm-9">
                            <p className="font-19">{}Total order processed</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} lg={4}>
                      <div className="storeCol bg2">
                        <div className="row justufy-content-center align-items-center m-0">
                          <div className="col-sm-3">
                            <div className="numberWrap">
                              <p>
                                {packageCOuntForPA.packageInHand
                                  ? packageCOuntForPA.packageInHand
                                  : "0"}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-9">
                            <p className="font-25">Packages in hand</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} lg={4}>
                      <div className="storeCol bg3">
                        <div className="row justufy-content-center align-items-center m-0">
                          <div className="col-sm-3">
                            <div className="numberWrap">
                              <p>
                                {packageCOuntForPA.incomingPackage
                                  ? packageCOuntForPA.incomingPackage
                                  : "0"}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-9">
                            <p className="font-25">Incoming packages</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default StoreProfile
