import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import matchSorter from "match-sorter";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col } from "react-bootstrap";
import delivaApiService from "../../../common/services/apiService";
import Pagination from "../DeliveryAgent/perfectpage";
import Template from "../../../common/helpers/Template";
class DeliveredOrders extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      searchInput: "",
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: "default", value: 5 }],
      pageSize : 5
    };
    this.filterAll = this.filterAll.bind(this);
  }
  componentDidMount() {
    this.getOrders();
  }
  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }

  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      // NOTE: this removes any FILTER ALL filter
      const filterAll = "";
      this.setState({
        filtered: filtered.filter((item) => item.id != "all"),
        filterAll,
      });
    } else this.setState({ filtered });
  }
  onRezise(page) {
    // if (filtered.length > 1 && this.state.filterAll.length) {
    //   // NOTE: this removes any FILTER ALL filter
    //   const filterAll = "";
    //   this.setState({
    //     filtered: filtered.filter((item) => item.id != "all"),
    //     filterAll,
    //   });
    // } else this.setState({ filtered });
  }
  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: "all", value: filterAll }];
    this.setState({ filterAll, filtered });
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.details.filter((value) => {
      return (
        value.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.destinationLocation
          .toLowerCase()
          .includes(searchInput.toLowerCase())
        // ||
        // value.visits
        //   .toString()
        //   .toLowerCase()
        //   .includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  getOrders() {
//   getListOfDeliveredRequests,getListOfInTransitRequests,getAllOrders,getListOfCancelledRequests,
  //   
    // this.setLoader(true);
    delivaApiService("getListOfDeliveredRequests")
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState(
            { details: response.data.resourceData.totalOrders },
            () => {
              this.setState({
                data: this.state.details?.map((item) => {
                  return item;
                }),
              });
            }
          );
        } else {
          //   this.setLoader(false);
        }
      })
      .catch((err) => {
        // this.setLoader(false);
      });
  }
  hello(pageSize) {
    this.setState({
      pageSize,
    });
  }
  render() {
    const columns = [
      // "destinationLocation","orderDate","originatingLocation","status","title
      {
        Header: "Order ID ",
        id: "requestId",
        accessor: (d) => d.requestId,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "requestId", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["requestId"] }),
        filterAll: true,
      },
      {
        Header: "Order Name",
        id: "title",
        accessor: (d) => d.title,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["title"] }),
        filterAll: true,
      },
      {
        Header: "Pickup Location",
        id: "originatingLocation",
        accessor: (d) => d.originatingLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "originatingLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["originatingLocation"] }),
        filterAll: true,
      },
      {
        Header: "Destination Location",
        id: "destinationLocation",
        accessor: (d) => d.destinationLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "destinationLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["destinationLocation"] }),
        filterAll: true,
      },
      {
        Header: "Status",
        id: "status",
        accessor: (d) => d.status,
        Cell: (props) => <span className="yellow-btn">{props.value}</span>, // Custom cell components!
        // accessor: "status", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["status"] }),
        filterAll: true,
      },

      {
        Header: "Action",
        Cell: (props) => (
          <a href className="pointer views-icos">
            <span className="icon-eye"></span> <span>View</span>
          </a>
        ), // Custom cell components!
      },
      {
        Header: "All",
        id: "all",
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => {},
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {
          const result = matchSorter(rows, filter.value, {
            keys: [
              "destinationLocation",
              "orderDate",
              "originatingLocation",
              "status",
              "title",
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24">
                        Open Delivery Request <span className="color-grey">(200)</span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht color-head">
                        Delivery Agents
                        <span className="icon-next mx-2 Fs12"></span> Delivery
                        agent’s profile{" "}
                        <span className="icon-next mx-2 Fs12"></span>
                        {Template.TOTALORDERS}
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.searchInput || ""}
                        onChange={this.handleChange}
                        label="Search"
                        type="text"
                        placeholder="Search by CustomerID, Location, Status, Name"
                        className="searchTxt w-100"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-wrap">
                        <ReactTable
                          data={this.state.data}
                          columns={this.state.pageSize ? columns : []}
                          //   pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                          defaultPageSize={this.state.defaultPageSize}
                          pageSize={this.state.pageSize}
                          showPageSizeOptions={true}
                          filtered={this.state.filtered}
                          //   resized={this.state.resized}
                          ref={(r) => (this.reactTable = r)}
                          onFilteredChange={this.onFilteredChange.bind(this)}
                          onPageSizeChange={(pageSize, pageIndex) => {
                            // console.log(pageSize, pageIndex);
                          }}
                          //   filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.age]) === filter.value
                          }
                            PaginationComponent={Pagination}
                        />
                        <span className="page-value">
                            Enter Per Page{" "}
                            <input
                              type="text"
                              placeholder="07"
                              name="defaultPageSize"
                              value={this.state.pageSize}
                              onChange={(e) => this.hello(e.target.value)}
                            />
                          </span>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default DeliveredOrders;
