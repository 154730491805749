import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import matchSorter from "match-sorter";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col } from "react-bootstrap";
import delivaApiService from "../../../common/services/apiService";
import { getLocalStorage } from "../../../common/helpers/Utils";
import Pagination from "./perfectpage";
import CONSTANTS from '../../../common/helpers/Constants';
import Template from "../../../common/helpers/Template";
import Loader from "../../common/Loader";

class DeliveryAgentTotalOrders extends Component {
  constructor() {
    super();
    this.state = {
      showLoader: false,
      name: "React",
      searchInput: "",
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: "default", value: 5 }],
      pageSize: 5,
    };
    this.filterAll = this.filterAll.bind(this);
  }
  componentDidMount() {
    this.getDAOrders();
  }
  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }

  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      // NOTE: this removes any FILTER ALL filter
      const filterAll = "";
      this.setState({
        filtered: filtered.filter((item) => item.id != "all"),
        filterAll,
      });
    } else this.setState({ filtered });
  }
  onRezise(page) {
    // if (filtered.length > 1 && this.state.filterAll.length) {
    //   // NOTE: this removes any FILTER ALL filter
    //   const filterAll = "";
    //   this.setState({
    //     filtered: filtered.filter((item) => item.id != "all"),
    //     filterAll,
    //   });
    // } else this.setState({ filtered });
  }
  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: "all", value: filterAll }];
    this.setState({ filterAll, filtered });
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.details.filter((value) => {
      return (
        value?.packageName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        value?.pickUpLocation?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        value?.destinationLocation?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        value?.orderId?.toString().includes(searchInput)
      );
    });
    this.setState({ data: filteredData });
  };

  getDAOrders() {
    this.setState({showLoader:true});
    delivaApiService("getTotalOrdersForDA", { id: getLocalStorage("DAid") })
      .then((response) => {
        this.setState({showLoader:false});
        if (response && response.data && response.data.resourceData) {
          this.setState({ 
              details: response.data.resourceData.totalDAOrders,
              totalOrderCount: response.data.resourceData.totalDAOrderCount
            },
            () => {
              this.setState({
                data: this.state.details?.map((item) => {
                  return item;
                }),
              });
            }
          );
        } else {
          //   this.setLoader(false);
        }
      })
      .catch((err) => {
        // this.setLoader(false);
      });
  }
  hello(pageSize) {
    this.setState({
      pageSize,
    });
  }
  deliveryAgentDetail = (props) => {
    this.props.history.push('/totalorderda/details/' + props);
    // }
  };

  orderStatus = (status) => {
    if (status === CONSTANTS.ORDER_STATUS.TRANSIT) {
      return 'In transit';
    } else if (status === CONSTANTS.ORDER_STATUS.ACCEPTED) {
      return 'Accepted bid';
    } else if (status === CONSTANTS.ORDER_STATUS.DELIVERED) {
      return 'Delivered';
    } else if (status == CONSTANTS.ORDER_STATUS.CANCELLED) {      
      return 'Cancelled';
    } else if (status === CONSTANTS.ORDER_STATUS.REJECTED) {
      return 'Pickup rejected';
    }
  };

  render() {
    const columns = [
      // "destinationLocation","orderDate","originatingLocation","status","title
      {
        Header: "Order Id ",
        id: "orderId",
        accessor: (d) => d.orderId,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "requestId", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["orderId"] }),
        filterAll: true,
      },
      {
        Header: "Order name",
        id: "packageName",
        accessor: (d) => d.packageName,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["packageName"] }),
        filterAll: true,
      },     
      {
        Header: "Pickup location",
        id: "pickUpLocation",
        accessor: (d) =>
          d?.pickUpLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "sourcePaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sourcePaDetail.city"] }),
        filterAll: true,
      },
      {
        Header: "Destination location",
        id: "destinationLocation",
        accessor: (d) =>
          d?.destinationLocation ,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "destinationPaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["destinationLocation"],
            // keys: ["destinationPaDetail.city"],
          }),
        filterAll: true,
      },
      {
        Header: "Status",
        id: "status",
        show : true,
       accessor: (d) => this.orderStatus(d.status),
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "status", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["status"] }),
        filterAll: true,
      },

      {
        Header: "Action",
        id: "orderId",
        accessor: (d) => d.orderId,
        Cell: (props) => (
          <a
            href="#"
            className="pointer views-icos"
            onClick={() => this.deliveryAgentDetail(props.value)}
          >
            <span className="icon-eye"></span> <span>View</span>
          </a>
        ), // Custom cell components!
      },
      {
        Header: "All",
        id: "all",
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => {},
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {
          const result = matchSorter(rows, filter.value, {
            keys: [
              "destinationLocation",
              "orderDate",
              "originatingLocation",
              "status",
              "title",
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable delivery-tot-order">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24">
                      {Template.TOTALORDERS} <span className="color-grey">({this.state.totalOrderCount})</span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht color-head">
                      <span
                          onClick={() =>
                            this.props.history.push(
                              '/deliveryagentlist'
                            )
                          }
                          className="pointer"
                        >
                          Delivery agents
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        <span
                          onClick={() =>
                            this.props.history.push(
                              '/deliveryagentprofile'
                            )
                          }
                          className="pointer"
                        >
                         Delivery
                        agent’s profile{" "}
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        Total orders
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.searchInput || ""}
                        onChange={this.handleChange}
                        label="Search"
                        type="text"
                        placeholder="Search by Order Id and Order name, Location"
                        className="searchTxt w-100"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-wrap">
                        <ReactTable
                          data={this.state.data}
                          columns={this.state.pageSize ? columns : []}
                          //   pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                          defaultPageSize={this.state.defaultPageSize}
                          pageSize={this.state.pageSize}
                          showPageSizeOptions={true}
                          filtered={this.state.filtered}
                          //   resized={this.state.resized}
                          ref={(r) => (this.reactTable = r)}
                          onFilteredChange={this.onFilteredChange.bind(this)}
                          onPageSizeChange={(pageSize, pageIndex) => {
                          }}
                          //   filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.age]) === filter.value
                          }
                          PaginationComponent={Pagination}
                        />
                        <span className="page-value">
                          Enter Per Page{" "}
                          <input
                            type="text"
                            placeholder="07"
                            name="defaultPageSize"
                            value={this.state.pageSize}
                            onChange={(e) => this.hello(e.target.value)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}
export default DeliveryAgentTotalOrders;
