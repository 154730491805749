import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import * as serviceWorker from './serviceWorker'
import Routes from './routes/DelivaRoute'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'
import { BrowserRouter } from 'react-router-dom'
import reducers from './common/redux/reducers'
import 'bootstrap/dist/css/bootstrap.min.css'
// import App from './App';
import './App.scss'
import WebFont from 'webfontloader'
// import './assets/scss/home.scss';
// import './assets/scss/icomoon.scss';
// import './assets/scss/login.scss';
// import './assets/scss/vars.scss';

// import './assets/scss/home.scss';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk))
WebFont.load({
  google: {
    families: [
      'Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900',
      'sans-serif',
    ],
  },
})
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
