const CONSTANTS = {
  ROLES: {
    ADMIN: 1,
    CUSTOMER: 2,
    DELIVERY_AGENT: 3,
    PROCESSING_AGENT: 4,
    AUPER_ADMIN: 5,
    MARKETING_ADMIN: 6,
  },
  IV_LENGTH: 16,
  ENCRYPTION_KEY: 'sd5b75nb7577#^%$%*&G#CGF*&%@#%*&',
  CRYPTER_KEY:
    '0xffffffff,0xffffffff,0xffffffff,0xffffffff,0xffffffff,0xfffffff8',
  ORDER_STATUS: {
    OPEN: 'OPEN',
    TRANSIT: 'IN_TRANSIT',
    ACCEPTED: 'ACCEPTED_BID',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED',
    REJECTED: 'PICKUP_REJECTED',
    VERIFIED: 'PICKUP_VERIFIED',
  },
  MAXLENGTH: {
    EMAIL: 60,
    PASSWORD: 30,
    MOBILE: 11,
  },
  NOTIFICATIONACTIONS: {
    USER_REGISTRATION_CONFIRMATION: 'Customer',
    PAGINATIONSIZE: 5,
  },
  ORDERTYPES: {
    TOTAL: 'total',
    CANCELLED: 'cancelled',
    INTRANSIT: 'intransit',
    DELIVERED: 'delivered',
  },
  PAYMENTSTATUS: {
    PENDING: 'Pending',
    SUCCESS: 'Success',
  },
  DAIDPROOF: {
    DRIVINGLICENCE: 'DL',
    PASSPORTLICENCE: 'PL',
    NATIONALLICENCE: 'NL',
  },
  ORDERTYPE: {
    LOCAL: 'LOCAL',
    INTERNATIONAL: 'INTERNATIONAL',
  },
  SHOWRECORDS: {
    NOOFRECORDS: '10',
  },
  CURRENCY: {
    USD: 'USD',
    NGN: 'NGN',
  },
}

export default CONSTANTS
