import React, { Component } from "react";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col, Image, FormControl } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import userblock1 from "../../../assets/images/userblock1.svg";
import Loader from '../../common/Loader';
import CONSTANTS from "../../../common/helpers/Constants";
import delivaApiService from "../../../common/services/apiService";
import { getLocalStorage, range } from "../../../common/helpers/Utils";
import { withRouter } from "react-router-dom";
import OrderImage1 from "../../../assets/images/OrderImage1.svg";
import moment from 'moment';

class Notifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notification: [],
      showLoader: false,
      pageNumber: 1,
      pageSize: CONSTANTS.NOTIFICATIONACTIONS.PAGINATIONSIZE,
    };
  }

  componentDidMount() {
    if (getLocalStorage("adminInfo")) {
      this.getNotifications(getLocalStorage("id"));
    } else {
      this.props.history.push("/login");
    }
  }
  update() {
    this.getNotifications(getLocalStorage("id"));
  }

  getNotifications = (id) =>{
    this.setState({
      showLoader : true
    });
    let data = {
      userId: id,
      record: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    delivaApiService('getAllNotification', data).then(response=>{
      if(response && response.data && response.data.resourceData){
        this.setState({
          notification : response.data.resourceData?.notificationHeaderResponse[0]?.notificationResponse,
        });
        this.setState({
          showLoader : false
        });

      }
      else {
        this.setState({
          showLoader : false
        });

      }
    }).catch(err=>{
      console.log(err);
      this.setState({
        showLoader : false
      });

    });
  }

  render() {
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />
        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                    <Row noGutters className="mb-3">
                        <Col md={6} lg={8} className="">
                          <h4 className="font-weight-normal mb-0 line-ht color-33 fs-24">
                              Notifications{" "}
                              <span className="color-grey">
                              ({this.state.notification.length})
                              </span>
                          </h4>
                          
                        </Col>
                        
                    </Row>

                   <Loader showLoader={this.state.showLoader} />

                    {  this.state.notification.length !== 0  && !this.state.showLoader? 
                    <div>
                    {this.state.notification && this.state.notification.map((item, index) => {
                    return(
                    <Row noGutters className="mb-3" key={index}>
                        <Col md={12} lg={12} className="">
                          <div className="notification-block border-left-blue">
                            <span className="time-label">{moment(new Date(item.dateTime), "YYYYMMDD").fromNow()}</span>
                    <h5>{item.notifictionAction}</h5>
                    <h5 className="color-grey font-weight-normal">{item.notification}</h5>
                          </div>
                        </Col>
                    </Row>
                    )})}
                    </div>
                    : 
                    <h5 className="text-center mt-5">{this.state.showLoader ? null : 'No Records Available'}</h5>
                    }
                    
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default Notifications;
// export default withRouter(TotalOrderTable);
