import React, { Component } from 'react';
import { Image, Modal, Spinner } from 'react-bootstrap';
import loginImage from '../../assets/images/download.png';

class Loader extends Component {    
    render() {
        return (
            <Modal
                show={this.props.showLoader} 
                // onHide={this.props.showLoader}
                centered
                className="loaderModal"
            >
                {/* <Image src={loginImage} alt='' />   */}
                <div className="mx-auto">
                    <Spinner animation="border" />
                </div>
            </Modal>
        )}
}
export default Loader;  
