import React, { Component } from 'react'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import moment from 'moment'
import { Row, Col, Image, FormControl } from 'react-bootstrap'
import profileImg1 from '../../../assets/images/user.png'
import deliverAgentIco1 from '../../../assets/images/deliverAgentIco1.svg'
import deliverAgentIco2 from '../../../assets/images/deliverAgentIco2.svg'
import deliverAgentIco3 from '../../../assets/images/deliverAgentIco3.svg'
import deliverAgentIco4 from '../../../assets/images/deliverAgentIco4.svg'
import idPic from '../../../assets/images/id-pic.svg'
import emptyStar from '../../../assets/images/emptystar.svg'
import halfStar from '../../../assets/images/halfstar.svg'
import fullStar from '../../../assets/images/fullstar.svg'
import Template from '../../../common/helpers/Template'
import Loader from '../../common/Loader'
import delivaApiService from '../../../common/services/apiService'
import { getLocalStorage, setLocalStorage } from '../../../common/helpers/Utils'

class DeliveryAgentProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      profile_detail: {},
      totalOrder: '',
      trip: '',
      toBeDeliver: '',
      submittedBid: '',
      readyForPickup: '',
      openBid: '',
      cancelledOrder: '',
      cancelledBid: '',
    }
  }

  componentDidMount() {
    if (this.props && this.props.location?.state?.item) {
      this.deliveryAgentProfile(this.props.location?.state?.item.userId)
      this.deliveryAgentProfileCount(this.props.location?.state?.item.userId)
      setLocalStorage('DAid', this.props.location?.state?.item.userId)
    } else {
      this.deliveryAgentProfile(getLocalStorage('DAid'))
      this.deliveryAgentProfileCount(getLocalStorage('DAid'))
    }
  }
  deliveryAgentOrders = () => {
    // if(!item.blocked){
    this.props.history.push('/deliveryagentorder', getLocalStorage('DAid'))
    // }
  }
  deliveryAgentBids = (path) => {
    // if(!item.blocked){
    this.props.history.push(path + '/' + getLocalStorage('DAid'))
    // }
  }

  deliveryAgentProfile = (id) => {
    this.setState({
      showLoader: true,
    })
    delivaApiService('deliveryAgentprofile', {
      daId: id,
    })
      .then((response) => {
        this.setState({ showLoader: false })
        if (response.data.status == 200) {
          this.setState({ profile_detail: response.data.resourceData })
        }
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        })
      })
  }

  deliveryAgentProfileCount = (id) => {
    this.setState({
      showLoader: true,
    })
    delivaApiService('deliveryAgentprofileCount', {
      daId: id,
    })
      .then((response) => {
        this.setState({ showLoader: false })
        if (response.data.status == 200) {
          this.setState({
            totalOrder: response.data.resourceData.totalOrder,
            trip: response.data.resourceData.trip,
            toBeDeliver: response.data.resourceData.toBeDeliver,
            submittedBid: response.data.resourceData.submittedBid,
            readyForPickup: response.data.resourceData.readyForPickup,
            openBid: response.data.resourceData.openBid,
            cancelledOrder: response.data.resourceData.cancelledOrder,
            cancelledBid: response.data.resourceData.cancelledBid,
          })
        }
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        })
      })
  }

  render() {
    let drawRating = [1, 2, 3, 4, 5]
    const {
      profile_detail,
      totalOrder,
      trip,
      toBeDeliver,
      submittedBid,
      readyForPickup,
      openBid,
      cancelledOrder,
      cancelledBid,
    } = this.state

    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal fs22 mb-2 color-33">
                        Delivery agent’s profile{' '}
                      </h3>
                      <h5 className="font-weight-normal fs18 mb-0 color-head">
                        <span
                          onClick={() =>
                            this.props.history.push('/deliveryagentlist')
                          }
                          className="pointer"
                        >
                          {' '}
                          Delivery agents{' '}
                        </span>
                        <span className="icon-next mx-2 Fs12"></span> Delivery
                        agent’s profile{' '}
                      </h5>
                    </Col>
                  </Row>
                  <div className="cust-details-wrap mb-4 delivery-agent-bg">
                    <Row noGutters className="">
                      <Col md={6} lg={6} className="">
                        <div className="row">
                          <div className="col-sm-4 pr-0">
                            <div className="d-flex justify-content-center">
                              <Image
                                src={
                                  profile_detail.profileImageUrl
                                    ? profile_detail.profileImageUrl
                                    : profileImg1
                                }
                                className="img-fluid max-width-sm rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="col-sm-8 pl-1">
                            <div className="d-flex h-100 align-items-center">
                              <div className="pr-wrap">
                                <h4>
                                  {profile_detail.name
                                    ? profile_detail.name
                                    : ''}
                                </h4>

                                <div className="ratings my-2">
                                  {drawRating.map((item, i) => {
                                    const ratingFloor = Math.floor(
                                      profile_detail.daRating
                                    )
                                    const hasHalfStar =
                                      profile_detail.daRating - ratingFloor >=
                                      0.5

                                    if (i < ratingFloor) {
                                      return (
                                        <span key={i}>
                                          <Image
                                            src={fullStar}
                                            className="img-fluid"
                                          />
                                        </span>
                                      )
                                    } else if (
                                      i === ratingFloor &&
                                      hasHalfStar
                                    ) {
                                      return (
                                        <span key={i}>
                                          <Image
                                            src={halfStar}
                                            className="img-fluid"
                                          />
                                        </span>
                                      )
                                    } else {
                                      return (
                                        <span key={i}>
                                          <Image
                                            src={emptyStar}
                                            className="img-fluid"
                                          />
                                        </span>
                                      )
                                    }
                                  })}
                                  <span>{profile_detail?.daRating}</span>
                                </div>

                                <p className="color-grey">
                                  ID: #
                                  {profile_detail.userId
                                    ? profile_detail.userId
                                    : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6} lg={6} className="">
                        <div className="d-flex h-100 align-items-center bor-left pl-3">
                          <ul className="pl-2 detail-list mb-0 color-grey">
                            <li>
                              <span className="icon-phone"></span>+
                              {profile_detail.countryCode}{' '}
                              {profile_detail.mobile}
                            </li>
                            <li>
                              <span className="icon-email"></span>
                              {profile_detail.email}
                            </li>
                            <li className="d-flex">
                              <span className="icon-pin map-ico-user"></span>
                              {profile_detail.address}
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="row mt-4 mb-4">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="dash-b-wrapper p-dec">
                            <div className="row">
                              <div className="col-md-4 pl-0">
                                <Image
                                  src={deliverAgentIco1}
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                className="col-md-8 p-0 pointer"
                                onClick={() =>
                                  this.deliveryAgentBids(
                                    '/deliveryagentorders/totalorders'
                                  )
                                }
                              >
                                <h1 className="dark-grey t-value mb-0">
                                  {totalOrder}
                                </h1>
                                <h4 className="color-head font-weight-normal font-25">
                                  {' '}
                                  {Template.TOTALORDERS}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="dash-b-wrapper p-dec">
                            <div className="row">
                              <div className="col-md-4 pl-0">
                                <Image
                                  src={deliverAgentIco2}
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                className="col-md-8 p-0 pointer"
                                onClick={() =>
                                  this.deliveryAgentBids(
                                    '/deliveryagent/openbids'
                                  )
                                }
                              >
                                <h1 className="dark-grey t-value mb-0">
                                  {openBid}
                                </h1>
                                <h4 className="color-head font-weight-normal font-25">
                                  {' '}
                                  Open Bids{' '}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="dash-b-wrapper p-dec">
                            <div className="row">
                              <div className="col-md-4 pl-0">
                                <Image
                                  src={deliverAgentIco3}
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                className="col-md-8 p-0 pointer"
                                onClick={() =>
                                  this.deliveryAgentBids(
                                    '/deliveryagent/totalbids'
                                  )
                                }
                              >
                                <h1 className="dark-grey t-value mb-0">
                                  {submittedBid}
                                </h1>
                                <h4 className="color-head font-weight-normal font-19">
                                  {' '}
                                  Total Submitted Bids{' '}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="dash-b-wrapper p-dec">
                            <div className="row">
                              <div className="col-md-4 pl-0">
                                <Image
                                  src={deliverAgentIco4}
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                className="col-md-8 p-0 pointer"
                                onClick={() =>
                                  this.deliveryAgentBids(
                                    '/deliveryagenttrips/totaltrips'
                                  )
                                }
                              >
                                <h1 className="dark-grey t-value mb-0">
                                  {trip}
                                </h1>
                                <h4 className="color-head font-weight-normal font-25">
                                  {' '}
                                  Total Trips{' '}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Row noGutters className="mb-3">
                    <Col md={6} lg={6}>
                      {profile_detail?.identificationDetails
                        ?.passportNumber && (
                        <div className="details-wrap">
                          <h4 className="pl-4 font-weight-normal fs20 color-head pb-1">
                            Passport Details
                          </h4>
                          <hr className="mt-2" />
                          <Row>
                            <Col sm={7}>
                              <h4 className="pl-4 font-weight-normal color-grey fs16">
                                International Passport Number
                              </h4>
                              <p className="fs20 pl-4 ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .passportNumber
                                  ? profile_detail.identificationDetails
                                      .passportNumber
                                  : ''}
                              </p>
                              <h4 className="pl-4 font-weight-normal color-grey fs16 mt-4">
                                Passport Issuing Country
                              </h4>
                              <p className="fs20 pl-4 ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .passportIssuingCountry
                                  ? profile_detail.identificationDetails
                                      .passportIssuingCountry
                                  : ''}
                              </p>
                            </Col>
                            <Col sm={5}>
                              <h4 className="font-weight-normal color-grey fs16">
                                Passport Expiration Date
                              </h4>
                              <p className="fs20  ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .passportExpirationDate
                                  ? moment(
                                      profile_detail.identificationDetails
                                        .passportExpirationDate
                                    ).format('DD MMM YYYY')
                                  : ''}
                              </p>
                              <h4 className="font-weight-normal color-grey fs16 mt-4">
                                ID Proof
                              </h4>
                              <div className="d-flex flex-wrap border-box-images">
                                {profile_detail.identificationDetails &&
                                (profile_detail.identificationDetails
                                  .passportFrontImageUrl ||
                                  profile_detail.identificationDetails
                                    .passportBackImageUrl) ? (
                                  <>
                                    <div className="img-inner-box">
                                      <Image
                                        className="img-fluid"
                                        src={
                                          profile_detail.identificationDetails &&
                                          profile_detail.identificationDetails
                                            .passportFrontImageUrl
                                            ? profile_detail
                                                .identificationDetails
                                                .passportFrontImageUrl
                                            : null
                                        }
                                      />
                                    </div>
                                    <div className="img-inner-box">
                                      <Image
                                        className="img-fluid"
                                        src={
                                          profile_detail.identificationDetails &&
                                          profile_detail.identificationDetails
                                            .passportBackImageUrl
                                            ? profile_detail
                                                .identificationDetails
                                                .passportBackImageUrl
                                            : null
                                        }
                                      />
                                    </div>{' '}
                                  </>
                                ) : (
                                  <div className="col-sm-12 text-center">
                                    Not available
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                      {profile_detail?.identificationDetails
                        ?.drivingLicenceNumber && (
                        <div className="details-wrap">
                          <h4 className="pl-4 font-weight-normal fs20 color-head pb-1">
                            Driving Details
                          </h4>
                          <hr className="mt-2" />
                          <Row>
                            <Col sm={7}>
                              <h4 className="pl-4 font-weight-normal color-grey fs16">
                                Driving Licence Number
                              </h4>
                              <p className="fs20 pl-4 ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .drivingLicenceNumber
                                  ? profile_detail.identificationDetails
                                      .drivingLicenceNumber
                                  : ''}
                              </p>
                              <h4 className="pl-4 font-weight-normal color-grey fs16 mt-4">
                                Licence Issuing Country
                              </h4>
                              <p className="fs20 pl-4 ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .licenceIssuingCountry
                                  ? profile_detail.identificationDetails
                                      .licenceIssuingCountry
                                  : ''}
                              </p>
                            </Col>
                            <Col sm={5}>
                              <h4 className="font-weight-normal color-grey fs16">
                                Licence Expiration Date
                              </h4>
                              <p className="fs20  ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .licenceExpirationDate
                                  ? moment(
                                      profile_detail.identificationDetails
                                        .licenceExpirationDate
                                    ).format('DD MMM YYYY')
                                  : ''}
                              </p>
                              <h4 className="font-weight-normal color-grey fs16 mt-4">
                                ID Proof
                              </h4>
                              <div className="d-flex flex-wrap border-box-images">
                                {profile_detail.identificationDetails &&
                                (profile_detail.identificationDetails
                                  .licenceFrontImageUrl ||
                                  profile_detail.identificationDetails
                                    .licenceBackImageUrl) ? (
                                  <>
                                    <div className="img-inner-box">
                                      <Image
                                        className="img-fluid"
                                        src={
                                          profile_detail.identificationDetails &&
                                          profile_detail.identificationDetails
                                            .licenceFrontImageUrl
                                            ? profile_detail
                                                .identificationDetails
                                                .licenceFrontImageUrl
                                            : null
                                        }
                                      />
                                    </div>
                                    <div className="img-inner-box">
                                      <Image
                                        className="img-fluid"
                                        src={
                                          profile_detail.identificationDetails &&
                                          profile_detail.identificationDetails
                                            .licenceBackImageUrl
                                            ? profile_detail
                                                .identificationDetails
                                                .licenceBackImageUrl
                                            : null
                                        }
                                      />
                                    </div>{' '}
                                  </>
                                ) : (
                                  <div className="col-sm-12 text-center">
                                    Not available
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}

                      {profile_detail?.identificationDetails
                        ?.nationalIdNumber && (
                        <div className="details-wrap">
                          <h4 className="pl-4 font-weight-normal fs20 color-head pb-1">
                            Natioal ID Details
                          </h4>
                          <hr className="mt-2" />
                          <Row>
                            <Col sm={7}>
                              <h4 className="pl-4 font-weight-normal color-grey fs16">
                                National ID Number
                              </h4>
                              <p className="fs20 pl-4 ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .nationalIdNumber
                                  ? profile_detail.identificationDetails
                                      .nationalIdNumber
                                  : ''}
                              </p>
                              {/* <h4 className="pl-4 font-weight-normal color-grey fs16 mt-4">
                                Passport Issuing Country
                              </h4>
                              <p className="fs20 pl-4 ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .passportIssuingCountry
                                  ? profile_detail.identificationDetails
                                      .passportIssuingCountry
                                  : ''}
                              </p> */}
                            </Col>
                            <Col sm={5}>
                              {/* <h4 className="font-weight-normal color-grey fs16">
                                Passport Expiration Date
                              </h4>
                              <p className="fs20  ">
                                {profile_detail.identificationDetails &&
                                profile_detail.identificationDetails
                                  .passportExpirationDate
                                  ? moment(
                                      profile_detail.identificationDetails
                                        .passportExpirationDate
                                    ).format('DD MMM YYYY')
                                  : ''}
                              </p> */}
                              <h4 className="font-weight-normal color-grey fs16">
                                ID Proof
                              </h4>
                              <div className="d-flex flex-wrap border-box-images">
                                {profile_detail.identificationDetails &&
                                (profile_detail.identificationDetails
                                  .nationalFrontImageUrl ||
                                  profile_detail.identificationDetails
                                    .nationalBackImageUrl) ? (
                                  <>
                                    <div className="img-inner-box">
                                      <Image
                                        className="img-fluid"
                                        src={
                                          profile_detail.identificationDetails &&
                                          profile_detail.identificationDetails
                                            .nationalFrontImageUrl
                                            ? profile_detail
                                                .identificationDetails
                                                .nationalFrontImageUrl
                                            : null
                                        }
                                      />
                                    </div>
                                    <div className="img-inner-box">
                                      <Image
                                        className="img-fluid"
                                        src={
                                          profile_detail.identificationDetails &&
                                          profile_detail.identificationDetails
                                            .nationalBackImageUrl
                                            ? profile_detail
                                                .identificationDetails
                                                .nationalBackImageUrl
                                            : null
                                        }
                                      />
                                    </div>{' '}
                                  </>
                                ) : (
                                  <div className="col-sm-12 text-center">
                                    Not available
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}

                      <div className="details-wrap mt-4">
                        <h4 className="pl-4 fs20 font-weight-normal color-head pb-1">
                          Banking Details
                        </h4>
                        <hr className="mt-2" />
                        <div className="row">
                          <div className="col-sm-6">
                            <h4 className="pl-4 font-weight-normal color-grey fs16">
                              Account Holder Name
                            </h4>
                            <p className="fs20 pl-4 ">
                              {profile_detail.bankingDetails &&
                              profile_detail.bankingDetails.fullName
                                ? profile_detail.bankingDetails.fullName
                                : ''}
                            </p>
                            <h4 className="pl-4 font-weight-normal color-grey fs16 mt-4">
                              Bank
                            </h4>
                            <p className="fs20 pl-4 ">
                              {profile_detail.bankingDetails &&
                              profile_detail.bankingDetails.bankName
                                ? profile_detail.bankingDetails.bankName + ' '
                                : ' '}

                              {profile_detail.bankingDetails &&
                              profile_detail.bankingDetails.bankLocation
                                ? profile_detail.bankingDetails.bankLocation
                                : ''}
                            </p>
                          </div>
                          {/* <div className="col-sm-6">
                            <h4 className="font-weight-normal color-grey fs16">
                              Account Number
                            </h4>
                            <p className="fs20 ">
                              {profile_detail.bankingDetails &&
                              profile_detail.bankingDetails.accountNumber
                                ? profile_detail.bankingDetails.accountNumber
                                : ''}
                            </p>
                            <h4 className="font-weight-normal color-grey fs16 mt-4">
                              ABA Number
                            </h4>
                            <p className="fs20 ">
                              {profile_detail.bankingDetails &&
                              profile_detail.bankingDetails.abaNumber
                                ? profile_detail.bankingDetails.abaNumber
                                : ''}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                    <Col md={6} lg={6} className="">
                      <div className=" ">
                        <div className="row m-0">
                          <div className="col-sm-6">
                            <div className="colored-wrap orange-box">
                              <h4 className="color-grey font-weight-normal font-35 text-capitalize w-100">
                                Ready To Pickup
                              </h4>
                              <h1 className="dark-grey t-value font-70 mb-0 opacity-dec">
                                {readyForPickup}
                              </h1>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="colored-wrap blue-box">
                              <h4 className="color-grey font-weight-normal font-35 text-capitalize w-100">
                                to Deliver
                              </h4>
                              <h1 className="dark-grey t-value font-70 mb-0 opacity-dec">
                                {toBeDeliver}
                              </h1>
                            </div>
                          </div>
                        </div>
                        <div className="row mx-0  mt-4">
                          <div className="col-sm-6">
                            <div className="colored-wrap purple-box">
                              <h4 className="color-grey font-weight-normal font-35 text-capitalize w-100">
                                Cancelled Bids
                              </h4>
                              <h1 className="dark-grey t-value font-70 mb-0 opacity-dec">
                                {cancelledBid}
                              </h1>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="colored-wrap yellow-box">
                              <h4 className="color-grey font-weight-normal font-35 text-capitalize w-100">
                                Cancelled Orders
                              </h4>
                              <h1 className="dark-grey t-value font-70 mb-0 opacity-dec">
                                {cancelledOrder}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    )
  }
}
export default DeliveryAgentProfile
