import React, { Component } from "react";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col, Image, FormControl, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import successfull from "../../../assets/images/successfull.svg";
// import Unblock from "../../../assets/images/Unblock.svg";
import delivaApiService from "../../../common/services/apiService";
import {
  getLocalStorage,
  range,
  clearLocalStorage,
  showSuccessToast,
} from "../../../common/helpers/Utils";
import { withRouter } from "react-router-dom";
import OrangeCard from "../../../assets/images/OrangeCard.svg";
import BlueCard from "../../../assets/images/BlueCard.svg";
import PinkCard from "../../../assets/images/PinkCard.svg";
import Loader from "../../common/Loader";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sort: "",
      keyword: "",
      search: "",
      totalOrders: [],
      delivaCommission: null,
      paymentCycle: null,
      paCommission: null,
      referralCommission: null,
    };
  }

  componentDidMount() {
    console.log(this.props);
    console.log("customer");

    if (getLocalStorage("adminInfo")) {
      this.getsetting()
    } else {
      this.props.history.push("/login");
    }
  }

  getsetting = () => {
    this.setLoader(true);
    delivaApiService("getSetting", {})
      .then((response) => {
        if (response && response.data) {
          this.setState(
            {
              paymentCycle: response.data.paymentCycle,
              delivaCommission: response.data.delivaCommission,
              paCommission: response.data.paCommission,
              referralCommission: response.data.referralCommission,
            },
            () => {
              this.setLoader(false);
            }
          );
        }
        else {
          this.setLoader(false);
        }
      })
      .catch((error) => {
        this.setLoader(false);
      });
  }
  update() { }


  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }

  handleLogout() {
    clearLocalStorage();
    this.props.history.push("/login");
  }
  handleUpdate = (type, val) => {
    console.log(val, type, "val")
    this.setLoader(true);
    let data = {
      [type]: parseInt(val),
    }
    delivaApiService([type], data)
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          showSuccessToast(response.data.responseMessage)
          this.getsetting()
        }
        else {
          this.setLoader(false);
        }
      })
      .catch((error) => {
        this.setLoader(false);
      });
  }

  handleChange = (type, event) => {
    const { value } = event.target;
      this.setState({
        [type]: value,
      }); 
  };

  render() {
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24">
                        Application level settings{" "}
                      </h3>
                    </Col>
                  </Row>
                  <div className="setting-block p-4">
                    <div className="process-list">
                      <Row className="align-items-center">
                        <Col lg={5}>
                          <h3 className="mb-0 paradate">Deliva commission</h3>
                        </Col>
                        <Col lg={5}>
                          <div className="d-flex align-items-center">
                            <Form.Control
                              placeholder="Enter Number"
                              value={this.state.delivaCommission}
                              onChange={(e) => this.handleChange('delivaCommission', e)}
                            />
                            <p className="paradate mb-0 minw-80 ml-3">%</p>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <button disabled={!this.state.delivaCommission} onClick={() => this.handleUpdate('delivaCommission', this.state.delivaCommission)} className="btnDefault btnOutline">Apply</button>
                        </Col>
                      </Row>
                    </div>
                    <div className="process-list">
                      <Row className="align-items-center">
                        <Col lg={5}>
                          <h3 className="mb-0 paradate">Processing agent commission</h3>
                        </Col>
                        <Col lg={5}>
                          <div className="d-flex align-items-center">
                            <Form.Control
                              placeholder="Enter Number"
                              value={this.state.paCommission}
                              onChange={(e) => this.handleChange('paCommission', e)}
                            />
                            <p className="paradate mb-0 minw-80 ml-3">%</p>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <button disabled={!this.state.paCommission} onClick={() => this.handleUpdate('paCommission', this.state.paCommission)} className="btnDefault btnOutline">Apply</button>
                        </Col>
                      </Row>
                    </div>
                    <div className="process-list">
                      <Row className="align-items-center">
                        <Col lg={5}>
                          <h3 className="mb-0 paradate">Referral commission</h3>
                        </Col>
                        <Col lg={5}>
                          <div className="d-flex align-items-center">
                            <Form.Control
                              placeholder="Enter Number"
                              value={this.state.referralCommission}
                              onChange={(e) => this.handleChange('referralCommission', e)}
                            />
                            <p className="paradate mb-0 minw-80 ml-3">%</p>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <button disabled={!this.state.referralCommission} onClick={() => this.handleUpdate('referralCommission', this.state.referralCommission)} className="btnDefault btnOutline">Apply</button>
                        </Col>
                      </Row>
                    </div>
                    <div className="process-list">
                      <Row className="align-items-center">
                        <Col lg={5}>
                          <h3 className="mb-0 paradate">Payment cycle</h3>
                        </Col>
                        <Col lg={5}>
                          <div className="d-flex align-items-center">
                            <Form.Control
                              placeholder="Enter Number"
                              value={this.state.paymentCycle}
                              onChange={(e) => this.handleChange('paymentCycle', e)}
                            />
                            <p className="paradate mb-0 minw-80 ml-3">Days</p>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <button disabled={!this.state.paymentCycle} onClick={() => this.handleUpdate('paymentCycle', this.state.paymentCycle)} className="btnDefault btnOutline">Apply</button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}
export default Settings;
// export default withRouter(TotalOrderTable);
