import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class Footer extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="border-0 mb-0">
          <Row noGutters className="bor-top py-3">
            <Col md={12} lg={12} className="logo">
              <p className="footer-txt mb-0 pt-0">
                DelivaHere ©{new Date().getFullYear()} All Rights Reserved.
                &nbsp;&nbsp;&nbsp;{" "}
                <a className="primary-color-new" href="https://delivahere.com/terms/" target="_blank">
                  Terms and Conditions | 
                </a>
               
                <a className="primary-color-new ml-1" href="https://delivahere.com/terms/#privacy" target="_blank">
                Privacy Policy
                </a>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Footer;
