import Cryptr from "cryptr";
import { toast } from "react-toastify";
import moment from "moment";
import CONSTANTS from "../helpers/Constants";
import _ from "lodash";
// import AWS from "aws-sdk";

var cryptlib = require("cryptlib"),
  iv = "F@$%^*GD$*(*#!12", //16 bytes = 128 bit
  key = cryptlib.getHashSha256(CONSTANTS.ENCRYPTION_KEY, 32); //32 bytes = 256 bits
  let DURATION_IN_SECONDS = {
    epochs: ["year", "month", "day", "hour", "minute"],
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
  };
const cryptr = new Cryptr(CONSTANTS.CRYPTER_KEY);

let toastId = "";

// export const fileEvent = (file, callback) => {
//   // const AWSService = AWS;
//   const region = "us-east-2";
//   const bucketName = "lifesavor-bucket";
//   const accessKey = "AKIAILBFQGZHB6YL5R6Q";
//   const secretKey = "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";

//   // const file = fileInput.target.files;

//   AWS.config.update({
//     accessKeyId: accessKey,
//     secretAccessKey: secretKey,
//     region: region,
//   });

//   const s3 = new AWS.S3({ params: { Bucket: bucketName } });

//   // console.log(file);
//   console.log(s3);

//   // const image = Object.values(file);
//   // console.log(image[0]);

//   var data = {
//     Key: "name",
//     Body: file,
//     ContentEncoding: "base64",
//     ContentType: "image/jpeg",
//   };
//   s3.putObject(data, function (err, data) {
//     if (err) {
//       console.log(err);
//       console.log("Error uploading data: ", data);
//     } else {
//       console.log("succesfully uploaded the image!", data);
//     }
//   });

//   const upload = s3.putObject(data);

//   upload.on((res) => {
//     console.log(res);
//   });

//   // const upload = s3.upload(
//   //   {
//   //     Key: 'abc/abc', Bucket: bucketName, Body: file, ACL: 'public-read'
//   //   }
//   // );
//   console.log(upload);
//   upload.send((err, data) => {
//     if (data) {
//       if (callback) callback(data);
//     }
//     console.log(data);
//     console.log(data);
//     if (err) {
//       console.log(err);
//     }
//   });
// };

// fileEvent(path , file) {
//   const AWSService = AWS;
//   const IdentityPoolId = 'us-east-2:5dd5af4e-8aaa-4ea5-9d10-2f760cb176b6';
//   const region = 'us-east-2';
//   const bucketName = 'lifesavor-bucket';

//   // const file = fileInput.target.files;

//   AWSService.config.update({
//     region: region,
//     credentials: new AWSService.CognitoIdentityCredentials({
//       IdentityPoolId: IdentityPoolId
//     })
//   });
//   const s3 = new S3({
//     params: { Bucket: bucketName }
//   });
//   this.image = Object.values(file);

//   const upload = s3.upload(
//     { Key: this.path +
//     '/ ' + Date.now() + this.image[0], Bucket: bucketName, Body: this.image[0], ACL: 'public-read' }
//   );

//   upload.send((err, data) => {
//     console.log(data);
//     this.profilePic = data.Location;
//     this.spinner.hide('merc');
//     this.spinner.hide('logo');
//     if (err) {
//       this.spinner.hide('merc');
//       this.spinner.hide('logo');
//     }
//   });
// }

// export const imageUpload = async (base64, path, callback) => {
//   const IdentityPoolId = "us-east-2:5dd5af4e-8aaa-4ea5-9d10-2f760cb176b6";
//   const region = "us-east-2";
//   const bucketName = "lifesavor-bucket";

//   AWS.config.update({
//     region: region,
//     credentials: new AWS.CognitoIdentityCredentials({
//       IdentityPoolId: IdentityPoolId,
//     }),
//   });

//   const s3 = new AWS.S3();
//   const base64Data = new Buffer.from(
//     base64.replace(/^data:image\/\w+;base64,/, ""),
//     "base64"
//   );
//   const type = base64.split(";")[0].split("/")[1];
//   const userId = 1;

//   const params = {
//     Bucket: bucketName,
//     Key: path + "/" + Date.now() + ".jpg", // type is not required
//     Body: base64Data,
//     ACL: "public-read",
//     ContentEncoding: "base64", // required
//     ContentType: "image/jpeg",
//   };
//   console.log("param", params);
//   const upload = s3.upload(params);
//   console.log(upload);
//   // upload.send((err, data) => {
//   //   console.log(data);
//   // })
//   upload.send((err, data) => {
//     if (data) {
//       if (callback) callback(data);
//     }

//     if (err) {
//       console.log(err);
//     }
//   });
// };

export const encrypt = (text) => {
  let cryptText = cryptlib.encrypt(text, key, iv);
  return cryptText.replace(/\//g, "_deliva_");
};

export const decrypt = (text) => {
  let decrptText = text.replace(/_deliva_/g, "/");
  return cryptlib.decrypt(decrptText, key, iv);
};

//used to encryption of localstorage data
export const encryptedData = (data) => {
  return cryptr.encrypt(data);
};

//used to decrypt localstorage data
export const decryptedData = (data) => {
  return cryptr.decrypt(data);
};

// toastr messages for error
export const showErrorToast = (errorMessage, event) => {
  if (!toast.isActive(toastId)) {
    toastId = toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }
};

// toastr messages for success
export const showSuccessToast = (message) => {
  if (!toast.isActive(toastId)) {
    toastId = toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }
};

// To set local storage data
export const setLocalStorage = (key, value) => {
  // value = JSON.stringify(value);
  // const encodedData = encryptedData(value);
  localStorage.setItem(key, value);
};

// To get local storage data
export const getLocalStorage = (key) => {
  // if (key) {
  let data = localStorage.getItem(key);

  //   if (data) {
  //     data = JSON.parse(decryptedData(data));
  //     //data = JSON.parse(data);

  //     return data;
  //   }
  // }
  return data;
};

const generateRandomString = () => {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const generateTimestamp = (fileName) => {
  let extension = fileName.split(".").pop();
  let newFileName =
    moment().valueOf() + generateRandomString() + "." + extension;
  return newFileName;
};

// used to remove localstorage item
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

// used to clear localstorage
// To clear localstorage
export const clearLocalStorage = (cb) => {
  localStorage.clear();
  if (cb) cb();
};

export const isValidURL = (URL) => {
  if (URL) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(URL);
  }
};

export const isExistURL = (URL) => {
  if (URL) {
    var regexp = /(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w\.-]*)*\/?\S/;
    return regexp.test(URL);
  }
};

export const isURL = (str) => {
  if (str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  }
};

export const extractURL = (str) => {
  let getString = Array.isArray(str) ? str[0] : str;

  if (getString) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;

    var linkText = getString.match(urlRegex);

    if (Array.isArray(linkText)) {
      return linkText[0];
    } else {
      return linkText;
    }
  }
  return false;
};

export const getIPAddress = (onNewIP) => {
  //compatibility for firefox and chrome
  var myPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  var pc = new myPeerConnection({
      iceServers: [],
    }),
    noop = function () {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
    key;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer()
    .then(function (sdp) {
      sdp.sdp.split("\n").forEach(function (line) {
        if (line.indexOf("candidate") < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });

      pc.setLocalDescription(sdp, noop, noop);
    })
    .catch(function (reason) {
      // An error occurred, so handle the failure to connect
    });

  //listen for candidate events
  pc.onicecandidate = function (ice) {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
};

export const capitalize = (string) => {
  if (string) return _.startCase(_.toLower(string));
  else return null;
};

export const capitalizeFirstLetter = (string) => {
  console.log(string);
  console.log(string.charAt(0).toUpperCase() + string.slice(1));
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
};

export const range = (start, end) => {
  const inc = (end - start) / Math.abs(end - start);
  // return Array(end).fill(0).map((e,i)=>i+1)
  return Array.from(
    Array(Math.abs(end - start) + 1),
    (_, i) => start + i * inc
  );
};

function getDuration(seconds) {
  let epoch, interval;

  for (let i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
    epoch = DURATION_IN_SECONDS.epochs[i];
    interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
    if (interval >= 1) {
      return {
        interval: interval,
        epoch: epoch,
      };
    }
  }
}
export function timeSince(date, isResource) {
  if (date) {
    let seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let duration = getDuration(seconds);
    let suffix =
      duration &&
      duration.interval &&
      (duration.interval > 1 || duration.interval === 0)
        ? "s ago"
        : " ago";
    return duration && duration.interval
      ? duration.interval + " " + duration.epoch + suffix
      : isResource
      ? "Just now"
      : "";
  }
  return "";
}
export const removedUnderscore = (string) => {
  string = string?.split('_')?.join('');
  string = string?.toLowerCase()?.replace(/\b[a-z]/g, function(letter) {
    return letter?.toUpperCase();
});
  return string;
};

export const getAbbreviations = () => {
  const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZoneName;
};
