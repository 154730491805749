import React, { Component } from 'react'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import { Row, Col, Image, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import userblock1 from '../../../assets/images/userblock1.svg'
// import Unblock from "../../../assets/images/Unblock.svg";
import delivaApiService from '../../../common/services/apiService'
import { getLocalStorage, range } from '../../../common/helpers/Utils'
import InputGroup from 'react-bootstrap/InputGroup'
import Loader from '../../common/Loader'
import CONSTANTS from '../../../common/helpers/Constants'

class ProccessingCenters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sortBy: '',
      sortOrder: 'desc',
      search: '',
      pAList: [],
      searchByLocation: '',
    }
  }

  componentDidMount() {
    if (getLocalStorage('adminInfo')) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    } else {
      this.props.history.push('/login')
    }
  }
  update() {
    this.getList(1, this.state.records, this.state.sortBy, this.state.sortOrder)
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(1, e.target.value, this.state.sortBy, this.state.sortOrder)
    } else {
      this.setState({
        records: '',
      })
    }
  }

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (
        (this.state.search == '' || this.state.search.trim() == '') &&
        (this.state.searchByLocation == '' ||
          this.state.searchByLocation.trim() == '')
      ) {
        this.getList(
          1,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        )
      }
    })
  }

  handleSearch() {
    this.getList(1, this.state.records, this.state.sortBy, this.state.sortOrder)
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }

  onChangeSort(val) {
    this.setState({ sortOrder: this.state.sortOrder == 'desc' ? '' : 'desc' })
    if (this.state.sortOrder == 'desc') {
      this.getList(this.state.pageno, this.state.records, val, 'asc')
    } else {
      this.getList(this.state.pageno, this.state.records, val, 'desc')
    }
  }
  getList(pageno, records, sortBy, sortOrder) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sortBy: sortBy,
        sortOrder: sortOrder,
        pAList: [],
      },
      () => {
        this.setLoader(true)
      }
    )
    delivaApiService('processingAgentList', {
      id: getLocalStorage('id'),
      records: records == 0 ? CONSTANTS.SHOWRECORDS.NOOFRECORDS : records,
      pageNumber: pageno,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchByProfile: this.state.search,
      searchByLocation: this.state.searchByLocation,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.resourceData.totalCount)
          this.setState(
            {
              pAList: response.data.resourceData.pAList,
              totalCount: response.data.resourceData.totalCount,
            },
            () => {
              this.setLoader(false)
            }
          )
        } else {
          this.setState({
            pAList: [],
            totalCount: 0,
          })
          this.setLoader(false)
        }
      })
      .catch(
        this.setState({
          showLoader: false,
        })
      )
  }
  getPager(total) {
    this.setState(
      {
        records:
          this.state.records == 0
            ? CONSTANTS.SHOWRECORDS.NOOFRECORDS
            : this.state.records,
      },
      () => {
        let startPage = this.state.startPage
        let endPage = this.state.endPage
        let totalPage = Math.ceil(total / this.state.records)

        let pageno = this.state.pageno

        if (totalPage <= 5) {
          startPage = 1
          endPage = totalPage
        } else {
          if (pageno <= 3) {
            startPage = 1
            endPage = 5
          } else if (pageno + 1 >= totalPage) {
            startPage = totalPage - 4
            endPage = totalPage
          } else {
            startPage = pageno - 2
            endPage = pageno + 2
          }
        }
        let startIndex = (pageno - 1) * this.state.records
        let endIndex = Math.min(
          startIndex + this.state.records - 1,
          totalPage - 1
        )

        // create an array of pages to ng-repeat in the pager control
        let pageArray
        if (startPage == endPage) {
          console.log('startPage, endPage', startPage, endPage)

          pageArray = [1]
        } else {
          pageArray = range(startPage, endPage)
        }
        this.setState({
          // records: this.state.records,
          totalPage: totalPage,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pageArray: pageArray,
        })
      }
    )
  }

  onChangePage(page) {
    if (page == this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }
  blockUnblock() {
    this.setLoader(true)
    let data = {
      // block: this.state.blockFlag,
      userId: this.state.blockId,
    }
    delivaApiService('blockUnblockCustomer', data).then((response) => {
      console.log(response)
      this.handleInviteVendors('')
      this.setLoader(false)
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    })
  }

  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    })
  }
  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }
  clearSearch = (data) => {
    this.setState(
      {
        [data]: '',
        // search:'',
        // searchByLocation: ''
      },
      () => {
        this.getList(
          this.state.pageno,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        )
      }
    )
  }

  render() {
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <div noGutters className="mb-3 d-flex justify-content-between align-items-center">
                      <h3 className="font-weight-normal mb-0 line-ht fs-24 color-33 text-nowrap">
                        Processing Centres{" "}
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                      <InputGroup className="mb-2 search-wrap">
                        <InputGroup.Prepend className="search-inner-wrap">
                          <FormControl
                            type="text"
                            placeholder="Search by City/State/Country"
                            className="search1"
                            name="searchByLocation"
                            value={this.state.searchByLocation}
                            onChange={(e) => this.handleChangeInput(e)}
                            onKeyDown={this.handleEnter.bind(this)}
                          />
                          <span className="icon-search"></span>
                          <a
                            href="#"
                            className="icon-close1 pointer"
                            onClick={() => this.clearSearch("searchByLocation")}
                          ></a>
                        </InputGroup.Prepend>
                        <InputGroup className="search-inner-wrap ">
                          <FormControl
                            id="inlineFormInputGroup"
                            placeholder="Search by Registration No, Business Name"
                            className="pr-4 fs-lg-13"
                            name="search"
                            value={this.state.search}
                            onChange={(e) => this.handleChangeInput(e)}
                            onKeyDown={this.handleEnter.bind(this)}
                          />
                          {/* {this.state.search || this.state.searchByLocation ? */}
                          <span className="icon-search"></span>
                          <a
                            href="#"
                            className="icon-close1 pointer"
                            onClick={() => this.clearSearch("search")}
                          ></a>
                          {/* :null} */}
                        </InputGroup>
                        <button
                          className="find-btn"
                          onClick={() => this.handleSearch()}
                          disabled={!this.state.search && !this.state.searchByLocation}
                        >
                          Find
                        </button>
                      </InputGroup>
                   </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Registration No.{" "}
                              <a
                                href="#"
                                className="icon-up-and-down color-light-grey"
                                onClick={() =>
                                  this.onChangeSort("registrationNumber")
                                }
                              ></a>
                            </th>
                            <th>
                              Business Name{" "}
                              <a
                                href="#"
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("name")}
                              ></a>
                            </th>
                            <th>
                              Email{" "}
                              <a
                                href="#"
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("email")}
                              ></a>
                            </th>
                            <th>Contact </th>
                            <th>
                              Location{" "}
                              <a
                                href="#"
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("location")}
                              ></a>
                            </th>

                            <th>Status </th>
                            <th>Action </th>
                          </tr>
                        </thead>
                        <tbody className="ttl-order-table">
                          {!this.state.pAList.length ? (
                            <tr>
                              <td colSpan={7} style={{ textAlign: "center" }}>
                                No Records Available
                              </td>
                            </tr>
                          ) : (
                            this.state.pAList.map((item, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>#{item.userId ? item.userId : "-"}</td>
                                  <td>
                                    {item.businessName
                                      ? item.businessName
                                      : "-"}
                                  </td>
                                  <td>{item.email ? item.email : "-"}</td>
                                  <td>
                                    {item.mobile
                                      ? `+${item.countryCode}${" "}${
                                          item.mobile
                                        }`
                                      : "-"}
                                  </td>
                                  <td>
                                    {item && item.city ? item.city : "-"}{" "}
                                  </td>
                                  <td
                                    className={
                                      item.active == true
                                        ? "text-green"
                                        : "text-reddish"
                                    }
                                  >
                                    {item.active == true
                                      ? "Active"
                                      : "Inactive"}
                                  </td>
                                  <td
                                    className="pointer views-icos"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: "/storeprofile",
                                        state: {
                                          id: item.userId,
                                        },
                                      });
                                    }}
                                  >
                                    <span className="icon-eye"></span> View
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </Table>
                      {this.state.pAList.length ? (
                        <nav aria-label="Page navigation">
                          <ul className="pagination pg-blue deliva-pagination justify-content-end">
                            <li className="page-item">
                              <button
                                onClick={() =>
                                  this.onChangePage(this.state.pageno - 1)
                                }
                                disabled={
                                  this.state.pageno == 1 ||
                                  this.state.totalPage == 0
                                }
                                className="page-link rotate-180 control-btn"
                                aria-label="Previous"
                              >
                                <span className="icon-next"></span>
                                <span
                                  //className="prevNext"
                                  className={`sr-only ${
                                    this.state.pageno == 1 ||
                                    this.state.totalPage == 0
                                      ? ""
                                      : "active"
                                  }`}
                                >
                                  Previous
                                </span>
                              </button>
                            </li>
                            {this.state.totalPage > 0 &&
                              this.state.pageArray.map((page, ind) => {
                                return (
                                  <li className="page-item" key={Math.random()}>
                                    <a
                                      className={`page-link ${
                                        this.state.pageno == page
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => this.onChangePage(page)}
                                    >
                                      {page}
                                    </a>
                                  </li>
                                );
                              })}

                            <li className="page-item">
                              <button
                                onClick={() =>
                                  this.onChangePage(this.state.pageno + 1)
                                }
                                disabled={
                                  this.state.pageno == this.state.totalPage ||
                                  this.state.totalPage == 0
                                }
                                className="page-link control-btn"
                                aria-label="Next"
                              >
                                <span className="icon-next"></span>
                                <span
                                  className={`sr-only ${
                                    this.state.pageno == this.state.totalPage ||
                                    this.state.totalPage == 0
                                      ? ""
                                      : "active"
                                  }`}
                                >
                                  Next
                                </span>
                              </button>
                            </li>
                            <li className="page-value">
                              Enter Per Page{" "}
                              <input
                                type="text"
                                placeholder={CONSTANTS.SHOWRECORDS.NOOFRECORDS}
                                name="records"
                                value={this.state.records}
                                onChange={(e) => this.onChangeItemPerPage(e)}
                              />
                            </li>
                          </ul>
                        </nav>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}
export default ProccessingCenters
