import React, { Component } from 'react'

class AccountDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <section class="terms-section">
        <div class="container custom-container">
          <h1 class="heading overflow-hidden text-start mx-auto mb-4">
            PROCEDURE FOR ACCOUNT DELETION
          </h1>
          <div class="row">
            <div class="col-md-12">
              <h2 class="heading3 text-black mb-2 mt-3">
                The following steps outline the process for deleting an Deliva
                Account:
              </h2>
              <ul class="list-number p-0">
                <li>
                  1.Send an email to the Deliva Support line at
                  support@delivahere.com.
                </li>
                <li>
                  2.Confirm your account and identity with the Deliva Team.
                </li>
                <li>3.Provide a reason for account deletion.</li>
                <li>
                  4.The Deliva Team will take the necessary steps for account
                  deletion.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default AccountDelete
